import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";

import CustomDatePicker from "../../../components/form/CustomDatePicker";
import { dateToDateString } from "../../../models/primitives";
import NarrowFormItem from "../../PrognosAI/components/narrowform/NarrowFormItem";
import { MinMaxDates } from "../api/analysis";

interface NarrowTimeRangeFieldsProps {
  id: string;
  isContained: boolean;
  fromDate: string | null;
  toDate: string | null;
  onChange(
    patch: Partial<{ fromDate: string | null; toDate: string | null }>
  ): void;
  minMaxDates?: MinMaxDates;
}

export default function NarrowTimeRangeFields(
  props: NarrowTimeRangeFieldsProps
): JSX.Element {
  const { id, isContained, fromDate, toDate, minMaxDates, onChange } = props;

  const { t } = useTranslation();

  const showTimePrecedeError = fromDate && toDate && fromDate > toDate;

  return (
    <NarrowFormItem
      label={t("Time range")}
      htmlFor={`${id}-fromDate`}
      help={t(
        "Specifies the date range from which the selected data will be used. By default, the time range of the whole data set is used."
      )}
      isDefault={!isContained && !fromDate && !toDate}
      forceNarrow={isContained}
      standalone={isContained}
    >
      <div className="flex items-center space-x-2 text-sm">
        <CustomDatePicker
          id={`${id}-fromDate`}
          value={fromDate}
          defaultValue={minMaxDates?.minDate}
          minValue={getMinDateString(minMaxDates?.minDate)}
          maxValue={getMaxDateString(minMaxDates?.maxDate)}
          placeholder={t("First data point")}
          error={!!showTimePrecedeError}
          utc
          onChange={(fromDate) =>
            onChange({ fromDate: dateToDateString(fromDate), toDate })
          }
        />
        <span>{t("to")}</span>
        <CustomDatePicker
          id={`${id}-toDate`}
          value={toDate}
          defaultValue={minMaxDates?.maxDate}
          minValue={getMinDateString(minMaxDates?.minDate)}
          maxValue={getMaxDateString(minMaxDates?.maxDate)}
          placeholder={t("Last data point")}
          error={!!showTimePrecedeError}
          utc
          onChange={(toDate) =>
            onChange({ fromDate, toDate: dateToDateString(toDate) })
          }
        />
      </div>
      {showTimePrecedeError && (
        <p className="text-sm text-red-600">
          {t("The start date must precede the end date.")}
        </p>
      )}
    </NarrowFormItem>
  );
}

function getMinDateString(dateStr: string | null | undefined): string | null {
  if (!dateStr) {
    return null;
  }

  const year = getYear(dateStr);
  if (isNaN(year)) {
    return null;
  }

  return `${year}-01-01`;
}

function getMaxDateString(dateStr: string | null | undefined): string | null {
  if (!dateStr) {
    return null;
  }

  const year = getYear(dateStr);
  if (isNaN(year)) {
    return null;
  }

  return `${year}-12-31`;
}

function getYear(dateStr: string): number {
  const m = dateStr.match(/^(\d{4})-/);

  return parseInt(m?.[1] ?? "");
}
