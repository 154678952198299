import { useTranslation } from "react-i18next";

import { useQuery } from "@tanstack/react-query";
import { TFunction } from "i18next";

import {
  ArrowPathIcon,
  NoSymbolIcon,
  PlayCircleIcon,
  StopCircleIcon,
} from "@heroicons/react/24/outline";

import { HeroIcon } from "../../../../models/primitives";
import { activeAggregateCalcTasksQuery } from "../../api/tasks";
import { getTasksProgress } from "../../functions/task";
import {
  ModelRunTask,
  TaskStatus,
  runningNonCanceledTasks,
} from "../../models/task";
import { tenum } from "../../services/translationMappings";

type TaskAction = "start" | "cancel" | "restart" | "none";

export function getTaskActionIcon(action: TaskAction): HeroIcon {
  switch (action) {
    case "start":
      return PlayCircleIcon;
    case "cancel":
      return StopCircleIcon;
    case "none":
      return NoSymbolIcon;
    case "restart":
      return ArrowPathIcon;
  }
}

export const getCancelButton = (
  row: { tasks: string },
  onCancel: (taskId: string) => void,
  t: TFunction<"translation", undefined>
) => {
  const tasks = JSON.parse(row.tasks) as ModelRunTask[];

  let icon = getTaskActionIcon("cancel");
  let title = t("Stop the run");

  if (tasks.some((t) => t.status === "Canceling")) {
    title = t("Stopping the run");
    icon = getTaskActionIcon("none");
  }

  return {
    title,
    onClick: () =>
      tasks
        .filter((t) => runningNonCanceledTasks.includes(t.status))
        .forEach((t) => onCancel(t.taskId)),
    icon,
  };
};

export function useRunningAggregateCalcTasks(solutionId: string | number) {
  const { data: { items: aggregateTasks = [] } = {} } = useQuery(
    activeAggregateCalcTasksQuery(solutionId)
  );

  return aggregateTasks;
}

export function getModelRunTaskStatus(
  tasks: ModelRunTask[]
): TaskStatus | null {
  if (tasks.some((t) => t.status === "Canceling")) {
    return "Canceling";
  }
  if (tasks.some((t) => t.status === "Started")) {
    return "Started";
  }
  if (tasks.some((t) => t.status === "Enqueued")) {
    return "Enqueued";
  }
  if (tasks.some((t) => t.status === "Blocked")) {
    return "Blocked";
  }

  return null;
}

export function useModelRunTasksStatus(solutionId: string | number) {
  const { t } = useTranslation();

  const aggregateTasks = useRunningAggregateCalcTasks(solutionId);

  return (tasks: ModelRunTask[]): string => {
    const status = getModelRunTaskStatus(tasks);
    const progress = getTasksProgress(tasks);

    switch (status) {
      case "Canceling":
        return t("Canceling");
      case "Started":
        return progress
          ? t("Running ({{progress}}% finished)", { progress })
          : t("Running");
      case "Enqueued": {
        if (aggregateTasks.length > 0) {
          return t("Refreshing aggregates ({{progress}}% finished)", {
            progress,
          });
        }
        return t("Waiting");
      }
      case "Blocked":
        return t("Blocked");
      // this should not happen because these states are not running tasks:
      case "Canceled":
      case "Failed":
      case "Finished":
      case null:
        return [...new Set(tasks.map(({ status }) => tenum(status, t)))].join(
          ", "
        );
    }
  };
}
