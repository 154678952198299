import React, { type JSX } from "react";

import classNames from "classnames";

interface SkeletonChartProps {
  className?: string;
}

export default function SkeletonChart(props: SkeletonChartProps): JSX.Element {
  const { className } = props;
  return (
    <div
      className={classNames("flex items-baseline mt-4 space-x-6", className)}
    >
      <div className="w-full h-72 bg-gray-200 rounded-t-lg"></div>
      <div className="w-full h-56 bg-gray-200 rounded-t-lg"></div>
      <div className="w-full h-72 bg-gray-200 rounded-t-lg"></div>
      <div className="w-full h-64 bg-gray-200 rounded-t-lg"></div>
      <div className="w-full h-80 bg-gray-200 rounded-t-lg"></div>
      <div className="w-full h-72 bg-gray-200 rounded-t-lg"></div>
      <div className="w-full h-80 bg-gray-200 rounded-t-lg"></div>
    </div>
  );
}
