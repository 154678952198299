import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getShiftPlanPath } from "./shiftsAndStaffing";

const RunConfiguration = lazyWithRetry(
  () => import("../pages/RunConfiguration/RunConfiguration")
);

const RUN_CONFIGURATION_ROUTE = "runConfiguration";

export const runConfigurationRoute: RouteObject = {
  path: RUN_CONFIGURATION_ROUTE,
  element: <RunConfiguration />,
};

export function getRunConfigurationPath(
  solutionId: string | number,
  shiftPlanId: string | number
): string {
  return `${getShiftPlanPath(solutionId, shiftPlanId)}/${RUN_CONFIGURATION_ROUTE}`;
}
