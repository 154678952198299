import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";

import {
  BeakerIcon,
  CalendarDaysIcon,
  Cog6ToothIcon,
  CommandLineIcon,
  PlusIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/24/outline";

import { useSolution } from "../../../../../api/solutions";
import { HeroIcon } from "../../../../../models/primitives";
import { useCreateAlgorithm } from "../../../api/algorithmConfigs";
import SideMenu from "../../../components/sidemenu/SideMenu";
import SideMenuDivider from "../../../components/sidemenu/SideMenuDivider";
import SideMenuEntry from "../../../components/sidemenu/SideMenuEntry";
import useSortedAlgorithms from "../../../hooks/useSortedAlgorithms";
import { ModelDetail } from "../../../models/model";
import {
  ExtendedModelPage,
  ModelPage,
  ModelPageZod,
  getModelDetailPath,
} from "../../../routes/models";
import { getResultsPath } from "../../../routes/results";
import { tenum } from "../../../services/translationMappings";
import { getTaskActionIcon } from "../../Results/utils";
import { getNewAlgorithmConfigSettings } from "../utils";

interface ModelMenuProps {
  model: ModelDetail | undefined;
}

export default function ModelMenu(props: ModelMenuProps): JSX.Element {
  const { modelId } = useParams();
  if (!modelId) {
    throw new Error("URL parameter or :modelId not specified!");
  }

  const [, solutionId] = useSolution();
  const { model } = props;
  const pages = usePages();

  const page = useModelPage();

  const { t } = useTranslation();

  const algorithmConfigs = useSortedAlgorithms(model?.algorithmConfigs ?? []);

  const createAlgorithm = useCreateAlgorithm(modelId);
  const navigate = useNavigate();
  const handleAlgorithmAdd = async () => {
    const position =
      algorithmConfigs.length > 0
        ? Math.max(...algorithmConfigs.map((a) => a.pipelinePosition)) + 1
        : 1;
    const newAlgorithm = getNewAlgorithmConfigSettings("Prophet", position);
    const { algorithmConfigId } =
      await createAlgorithm.mutateAsync(newAlgorithm);
    navigate(
      `${getModelDetailPath(
        solutionId,
        modelId
      )}/algorithms/${algorithmConfigId}`
    );
  };

  return (
    <SideMenu>
      {pages
        .filter((p) => !outsiderPages.includes(p.id))
        .map((item) => (
          <SideMenuEntry
            key={item.id}
            id={item.id}
            name={item.name}
            href={`./${item.id}`}
            icon={item.icon}
            active={item.id === page}
          />
        ))}
      <SideMenuDivider />
      {algorithmConfigs.map((ac) => (
        <SideMenuEntry
          key={ac.algorithmConfigId}
          id={`algorithm-${ac.algorithmConfigId}`}
          name={
            ac.algorithmLabel ? ac.algorithmLabel : tenum(ac.algorithmName, t)
          }
          href={`./algorithms/${ac.algorithmConfigId}`}
          icon={BeakerIcon}
          active={`algorithms/${ac.algorithmConfigId}` === page}
        />
      ))}
      {!!model && (
        <SideMenuEntry
          id="algorithm-new"
          name={t("Add algorithm")}
          icon={PlusIcon}
          onClick={handleAlgorithmAdd}
        />
      )}
      {!!model && <SideMenuDivider />}
      {outsiderPages.map((id) => {
        const item = pages.find((p) => p.id === id);
        if (!item) {
          return null;
        }
        return (
          <SideMenuEntry
            key={item.id}
            id={item.id}
            name={item.name}
            href={`./${item.id}`}
            icon={item.icon}
            active={page === item.id}
          />
        );
      })}
      <SideMenuEntry
        id="modelResults"
        name={t("Results")}
        href={`${getResultsPath(solutionId)}?Filter=modelId = ${modelId}`}
        icon={PresentationChartLineIcon}
      />
    </SideMenu>
  );
}

export const outsiderPages: ModelPage[] = ["runConfigs"];

interface NavItem {
  id: ModelPage;
  name: string;
  icon: HeroIcon;
}

const usePages = (): NavItem[] => {
  const { t } = useTranslation();

  return [
    { id: "general", name: t("General Settings"), icon: Cog6ToothIcon },
    {
      id: "influencingFactors",
      name: t("Influencing factors"),
      icon: CalendarDaysIcon,
    },
    {
      id: "advanced",
      name: t("JSON definition (Expert user)"),
      icon: CommandLineIcon,
    },
    {
      id: "runConfigs",
      name: t("Run in scenarios"),
      icon: getTaskActionIcon("start"),
    },
  ];
};

export const useModelPage = (): ExtendedModelPage => {
  const location = useLocation();
  const paths = location.pathname.split("/");

  if (paths.length > 1 && paths[paths.length - 2] === "algorithms") {
    return `algorithms/${parseInt(paths[paths.length - 1])}`;
  }

  return ModelPageZod.catch(ModelPageZod.Enum.general).parse(
    paths[paths.length - 1]
  );
};
