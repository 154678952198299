import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";

import { useQuery } from "@tanstack/react-query";

import logo from "../../../public/images/logo.png";
import { csrfTokenQuery } from "../../api/user";
import Card from "../../components/Card";
import CircularLoading from "../../components/CircularLoading";
import Button from "../../components/buttons/Button";
import env from "../../services/env";
import PROGNOS_VERSION from "../../services/version";

interface LoginFormProps {
  className?: string;
  from: string;
}

export default function LoginForm({
  className = "",
  from,
}: LoginFormProps): JSX.Element {
  const { t } = useTranslation();

  const { data: csrf } = useQuery(csrfTokenQuery());

  const returnUrl =
    window.location.port === "3000"
      ? window.location.href + from.slice(1)
      : from;

  return (
    <div
      className={
        "flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8 " +
        className
      }
      style={{ width: 420 }}
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-16 w-auto" src={logo} alt="TIS Prognos AI" />
        <h2 className="mt-6 text-center text-xl font-bold tracking-tight text-gray-900">
          {t("Sign in to your account")}
        </h2>
      </div>
      <Card className="mt-8 sm:mx-auto sm:w-full sm:max-w-md h-48 flex flex-col items-center justify-center">
        {!csrf && <CircularLoading size={32} className="text-blue-500" />}
        {!!csrf && (
          <form
            action={`${env.API_URL}/accounts/openid_connect/login/?next=${returnUrl}`}
            method="POST"
          >
            <input
              type="hidden"
              name="csrfmiddlewaretoken"
              value={csrf.csrftoken}
            />
            <Button id="signInButton" type="submit" autoFocus>
              {t("Sign in using SSO account")}
            </Button>
          </form>
        )}
      </Card>
      <div className="w-full text-center text-xs md:text-[10px] text-gray-400 p-2">
        {PROGNOS_VERSION}
      </div>
    </div>
  );
}
