import React, { type JSX } from "react";
import { useLocation, useParams } from "react-router";

import { useSolution } from "../../../../api/solutions";
import Sidebar from "../../../../components/sidebar/Sidebar";
import BasicSkeleton from "../../../../components/skeletons/BasicSkeleton";
import ChartSkeleton from "../../../../components/skeletons/ChartSkeleton";
import ListPageSkeleton from "../../../../components/skeletons/ListPageSkeleton";
import SettingsSkeleton from "../../../../components/skeletons/SettingsSkeleton";
import useGeneralMenuItems from "../../hooks/useGeneralMenuItems";
import useSolutionMenuItems from "../../hooks/useSolutionMenuItems";
import ProjectDashboardSkeleton from "../../pages/Dashboard/components/ProjectDashboardSkeleton";
import FactorMenu from "../../pages/InfluencingFactor/components/FactorMenu";
import ModelMenu from "../../pages/Model/components/ModelMenu";
import OutlierMenu from "../../pages/Outlier/components/OutlierMenu";
import ResultDashboardSkeleton from "../../pages/Result/components/ResultDashboardSkeleton";
import RunMenu from "../../pages/Run/components/RunMenu";
import {
  getInfluencingFactorPath,
  getInfluencingFactorsImportDetailPath,
  getInfluencingFactorsPath,
} from "../../routes/influencingFactors";
import { getModelDetailPath, getModelsPath } from "../../routes/models";
import { getOutlierDetailPath, getOutliersPath } from "../../routes/outliers";
import { getPrognosPath } from "../../routes/prognos";
import {
  getResultDetailPath,
  getResultSegmentDetailPath,
  getResultsPath,
} from "../../routes/results";
import { getRunDetailPath, getRunsPath } from "../../routes/runConfigs";
import BasicLayout from "./BasicLayout";

interface PrognosLayoutProps {
  children?: React.ReactNode;
}

export default function PrognosLayout({ children }: PrognosLayoutProps) {
  const [, solutionId] = useSolution();

  const [projectTop, projectBottom] = useGeneralMenuItems(solutionId);
  const [solutionTop] = useSolutionMenuItems(solutionId);

  const topNavigation = [projectTop, solutionTop];

  const skeleton = useSkeleton();

  return (
    <Sidebar topNavigation={topNavigation} bottomNavigation={projectBottom}>
      <BasicLayout skeleton={skeleton}>{children}</BasicLayout>
    </Sidebar>
  );
}

function useSkeleton(): JSX.Element {
  const { pathname } = useLocation();
  const params = useParams();
  const { solutionId } = params;

  if (!solutionId) {
    return <BasicSkeleton />;
  }

  const { influencingFactorId, importExportConfigId } = params;
  // IF detail page
  if (
    influencingFactorId &&
    pathname.startsWith(
      getInfluencingFactorPath(solutionId, influencingFactorId)
    )
  ) {
    return (
      <SettingsSkeleton menu={<FactorMenu influencingFactor={undefined} />} />
    );
  }
  // IF import detail page
  if (
    importExportConfigId &&
    pathname.startsWith(
      getInfluencingFactorsImportDetailPath(solutionId, importExportConfigId)
    )
  ) {
    return <SettingsSkeleton />;
  }
  if (pathname.startsWith(getInfluencingFactorsPath(solutionId))) {
    return <ListPageSkeleton showTabs />;
  }

  const { outlierId } = params;
  // outlier detail page
  if (
    outlierId &&
    pathname.startsWith(getOutlierDetailPath(solutionId, outlierId))
  ) {
    return <SettingsSkeleton menu={<OutlierMenu outlier={undefined} />} />;
  }
  if (pathname.startsWith(getOutliersPath(solutionId))) {
    return <ListPageSkeleton showTabs />;
  }

  const { modelId } = params;
  // model detail page
  if (modelId && pathname.startsWith(getModelDetailPath(solutionId, modelId))) {
    return <SettingsSkeleton menu={<ModelMenu model={undefined} />} />;
  }
  if (pathname.startsWith(getModelsPath(solutionId))) {
    return <ListPageSkeleton />;
  }

  const { runConfigId } = params;
  // scenario detail page
  if (
    runConfigId &&
    pathname.startsWith(getRunDetailPath(solutionId, runConfigId))
  ) {
    return <SettingsSkeleton menu={<RunMenu />} />;
  }
  if (pathname.startsWith(getRunsPath(solutionId))) {
    return <ListPageSkeleton />;
  }

  const { runResultId, dataSegmentResultId } = params;
  // result segment detail page
  if (
    runResultId &&
    dataSegmentResultId &&
    pathname.startsWith(
      getResultSegmentDetailPath(solutionId, runResultId, dataSegmentResultId)
    )
  ) {
    return <ChartSkeleton withSwitch />;
  }
  // result dashboard page
  if (
    runResultId &&
    pathname.startsWith(getResultDetailPath(solutionId, runResultId))
  ) {
    return <ResultDashboardSkeleton runResultId={runResultId} />;
  }
  if (pathname.startsWith(getResultsPath(solutionId))) {
    return <ListPageSkeleton />;
  }

  // Prognos AI dashboard page
  if (pathname.startsWith(getPrognosPath(solutionId))) {
    return <ProjectDashboardSkeleton />;
  }

  return <BasicSkeleton />;
}
