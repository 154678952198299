import React, { type JSX } from "react";

export default function Form(
  props: React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  >
): JSX.Element {
  const { className = "", children, onSubmit, ...rest } = props;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (onSubmit) {
      e.preventDefault();
      onSubmit(e);
    }
  };

  return (
    <form
      className={`space-y-4 ${className}`}
      onSubmit={handleSubmit}
      {...rest}
    >
      {children}
    </form>
  );
}
