import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";

import {
  CodeBracketIcon,
  Cog6ToothIcon,
  HomeIcon,
  PresentationChartLineIcon,
  RectangleStackIcon,
  ShareIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import { useSolution } from "../../../../api/solutions";
import Sidebar, { SidebarItem } from "../../../../components/sidebar/Sidebar";
import BasicSkeleton from "../../../../components/skeletons/BasicSkeleton";
import ListPageSkeleton from "../../../../components/skeletons/ListPageSkeleton";
import SettingsSkeleton from "../../../../components/skeletons/SettingsSkeleton";
import { getTasksPath } from "../../../../routes/tasks";
import { getUserSettingsPath } from "../../../../routes/userSettings";
import BasicLayout from "../../../PrognosAI/components/layouts/BasicLayout";
import CanvasSkeleton from "../../pages/BusinessMap/components/CanvasSkeleton";
import { getBusinessMapPath } from "../../routes/businessMap";
import { getDemandPath } from "../../routes/demand";
import { getResultsPath } from "../../routes/results";
import { getSettingsPath } from "../../routes/settings";
import { getVariablesPath } from "../../routes/variables";

interface DemandLayoutProps {
  children?: React.ReactNode;
}

export default function DemandLayout({ children }: DemandLayoutProps) {
  const [topItems, bottomItems] = useDemandItems();
  const businessMapItems = useBusinessMapItems();

  const skeleton = useSkeleton();

  return (
    <Sidebar
      topNavigation={[topItems, businessMapItems]}
      bottomNavigation={bottomItems}
    >
      <BasicLayout skeleton={skeleton}>{children}</BasicLayout>
    </Sidebar>
  );
}

function useDemandItems(): [SidebarItem[], SidebarItem[]] {
  const [, solutionId] = useSolution();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const topItems: SidebarItem[] = [
    {
      key: "dashboard",
      name: t("Business maps"),
      href: getDemandPath(solutionId),
      icon: HomeIcon,
      isCurrent: pathname === getDemandPath(solutionId),
    },
  ];

  const bottomItems: SidebarItem[] = [
    {
      key: "userSettings",
      name: t("User settings"),
      href: getUserSettingsPath(solutionId, undefined),
      icon: UserIcon,
      isCurrent: location.pathname.startsWith(
        getUserSettingsPath(solutionId, undefined)
      ),
    },
    {
      key: "tasks",
      name: t("System tasks"),
      href: getTasksPath(solutionId, undefined),
      icon: RectangleStackIcon,
      isCurrent: location.pathname.startsWith(
        getTasksPath(solutionId, undefined)
      ),
    },
  ];

  return [topItems, bottomItems];
}

function useBusinessMapItems(): SidebarItem[] {
  const { solutionId, businessMapId } = useParams();
  const { pathname, search } = useLocation();
  const { t } = useTranslation();

  if (!solutionId || !businessMapId) {
    return [];
  }

  const items: SidebarItem[] = [
    {
      key: "businessMap",
      name: t("Business map"),
      href: getBusinessMapPath(solutionId, businessMapId) + search,
      icon: ShareIcon,
      isCurrent: pathname === getBusinessMapPath(solutionId, businessMapId),
    },
    {
      key: "settings",
      name: t("Settings"),
      href: getSettingsPath(solutionId, businessMapId) + search,
      icon: Cog6ToothIcon,
      isCurrent: pathname === getSettingsPath(solutionId, businessMapId),
    },
    {
      key: "variables",
      name: t("Variables"),
      href: getVariablesPath(solutionId, businessMapId) + search,
      icon: CodeBracketIcon,
      isCurrent: pathname.startsWith(
        getVariablesPath(solutionId, businessMapId)
      ),
    },
    {
      key: "results",
      name: t("Results"),
      href: getResultsPath(solutionId, businessMapId) + search,
      icon: PresentationChartLineIcon,
      isCurrent: pathname === getResultsPath(solutionId, businessMapId),
    },
  ];

  return items;
}

function useSkeleton(): JSX.Element {
  const { pathname } = useLocation();
  const { solutionId, businessMapId } = useParams();

  if (!solutionId) {
    return <BasicSkeleton />;
  }

  if (!businessMapId) {
    return <ListPageSkeleton />;
  }

  if (pathname === getBusinessMapPath(solutionId, businessMapId)) {
    return <CanvasSkeleton />;
  }

  // business map settings page
  if (pathname.startsWith(getSettingsPath(solutionId, businessMapId))) {
    return <SettingsSkeleton />;
  }

  // business map variables page
  if (pathname.startsWith(getVariablesPath(solutionId, businessMapId))) {
    return <ListPageSkeleton />;
  }

  // TODO variable detail skeleton

  // business map results page
  if (pathname.startsWith(getResultsPath(solutionId, businessMapId))) {
    // TODO better skeleton
    return <ListPageSkeleton />;
  }

  return <BasicSkeleton />;
}
