import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router";

import { useAuth } from "../../components/AuthProvider";
import SuiteHelmet from "../../components/helmet/SuiteHelmet";
import LoginForm from "./LoginForm";

export default function Login(): JSX.Element {
  const { t } = useTranslation();

  const location = useLocation() as {
    state?: { from: string };
  };

  const { isAuthenticated } = useAuth();

  const from = location.state?.from ?? `/auth`;

  if (isAuthenticated) {
    return <Navigate to={from} />;
  }

  return (
    <div className="flex justify-center">
      <SuiteHelmet title={t("Login")} />
      <LoginForm from={from} />
    </div>
  );
}
