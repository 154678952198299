import { TFunction, TOptions } from "i18next";

import {
  DayOfWeek,
  Invalid,
  PeriodUnit,
  ShortDayOfWeek,
} from "../../../models/primitives";
import { SolutionPhase } from "../../../models/solution";
import { ConvertValuesOption } from "../../Analyzer/api/analysis";
import { DemandVariableType } from "../../Demand/models/businessMap";
import {
  AggregationFn,
  AggregationMethod,
  TransformationMethod,
} from "../../Demand/models/canvasBlock";
import { AlgorithmName, PartialIFsRule } from "../models/algorithmConfig";
import { ChartType } from "../models/chart";
import { DataImportMode, ImportExportZone } from "../models/dataImportConfig";
import { DataImportConfigDependencyType } from "../models/dependency";
import {
  GeneralImportMode,
  ImportExportFileType,
  ImportExportSeparator,
} from "../models/file";
import { FactorSource } from "../models/influencingFactor";
import { OutlierDetectionMethod, StatisticalMeasure } from "../models/outlier";
import { ViewMode } from "../models/partition";
import { PageSection, PrognosPage } from "../models/problem";
import {
  ArimaType,
  CategoricalType,
  IntersectType,
  SeasonalityType,
  TrendType,
} from "../models/result";
import {
  InterpolationMethod,
  IntradayProfileMethod,
  PartialOutliersRule,
  ProfileReferenceType,
  RunTaskType,
} from "../models/run";
import { CorrectionType } from "../models/runCorrection";
import { SeriesType } from "../models/series";
import {
  DecimalSeparator,
  ExportColumnSeparator,
  ProfileYScale,
  ThousandsSeparator,
} from "../models/settings";
import { TaskStatus } from "../models/task";

type ComprehensiveEnum =
  | AlgorithmName
  | PartialIFsRule
  | PartialOutliersRule
  | RunTaskType
  | ChartType
  | InterpolationMethod
  | DecimalSeparator
  | ThousandsSeparator
  | `${"InfluencingFactors" | "BusinessHours"}:${GeneralImportMode}`
  | `History:${DataImportMode}`
  | TaskStatus
  | FactorSource
  | SeasonalityType
  | IntersectType
  | TrendType
  | DayOfWeek
  | ShortDayOfWeek
  | PeriodUnit
  | `uc:${PeriodUnit}`
  | OutlierDetectionMethod
  | StatisticalMeasure
  | ProfileYScale
  | SeriesType
  | PrognosPage
  | PageSection
  | IntradayProfileMethod
  | ProfileReferenceType
  | ArimaType
  | CategoricalType
  | `DataImportConfigDependencyType:${DataImportConfigDependencyType}`
  | `CorrectionType:${CorrectionType}`
  | `ViewMode:${ViewMode}`
  | ImportExportFileType
  | ExportColumnSeparator
  | ImportExportSeparator
  | `SolutionPhase:${SolutionPhase}`
  | ImportExportZone
  | `ConvertValues:${ConvertValuesOption}`
  | `AggregationFn:${AggregationFn}`
  | `TransformationMethod:${TransformationMethod}`
  | `AggregationMethod:${AggregationMethod}`
  | `DemandVariableType:${DemandVariableType}`
  | Invalid;

export function tenum(
  value: ComprehensiveEnum,
  t: TFunction,
  options?: TOptions
): string {
  switch (value) {
    // AlgorithmName
    case "Prophet":
      return t("Prophet");
    case "SeasonalRegression":
      return t("Seasonal Regression");
    case "Naive":
      return t("Naive methods");
    case "ExponentialSmoothing":
      return t("Exponential Smoothing");
    case "Arima":
      return t("(S)ARIMA");
    case "Nbeats":
      return t("N-BEATS");
    // PartialIFsRule & PartialOutliersRule
    case "Always":
      return t("Yes");
    case "Never":
      return t("No");
    case "50%":
      return t("At least 50% contained");
    case "Proportional":
      return t("Proportionally");
    // RunTaskType
    case "Backtest":
      return t("Backtest");
    case "Validation":
      return t("Validation");
    case "Forecast":
      return t("Forecast");
    // ChartType
    case "Series":
      return t("Data series");
    case "BoxPlot":
      return t("Box Plot");
    case "Histogram":
      return t("Histogram");
    case "Yoy":
      return t("Yearly data series");
    // InterpolationMethod
    case "Constant":
      return t("constant");
    case "Linear":
      return t("Linear");
    case "Cubic":
      return t("Cubic");
    case "RollingMean":
      return t("rolling mean");
    case "RollingMedian":
      return t("rolling median");
    // DecimalSeparator & ThousandsSeparator
    case "Point":
      return t("Point");
    case "Comma":
      return t("Comma");
    case "Space":
      return t("Space");
    // GeneralImportMode
    case "BusinessHours:Replace":
      return t("Delete all existing business hours");
    case "BusinessHours:Overwrite":
      return t("Overwrite overlapping data");
    case "BusinessHours:Append":
      return t("Ignore overlapping data (append only)");
    case "InfluencingFactors:Replace":
      return t("Delete all existing influencing factors");
    case "InfluencingFactors:Overwrite":
      return t("Overwrite matching influencing factors");
    case "InfluencingFactors:Append":
      return t("Ignore matching influencing factors (append only)");
    // DatasetImportMode
    case "History:Replace":
      return t("Replace all time ranges and hierarchy");
    case "History:Overwrite":
      return t("Update existing and append new time ranges");
    case "History:Append":
      return t("Keep existing and append new time ranges");
    case "History:SoftReplace":
      return t("Replace all time ranges (keep hierarchy)");
    // TaskStatus
    case "Enqueued":
      return t("Waiting");
    case "Blocked":
      return t("Blocked");
    case "Started":
      return t("Running");
    case "Canceling":
      return t("Canceling");
    case "Canceled":
      return t("Canceled");
    case "Failed":
      return t("Failed");
    case "Finished":
      return t("Finished");
    case "Manual":
      return t("User-defined");
    case "Automatic":
      return t("Autodetected");
    // SeasonalityType
    case "Daily":
      return t("Daily");
    case "Weekly":
      return t("Weekly");
    case "Monthly":
      return t("Monthly");
    case "Yearly":
      return t("Yearly");
    case "Seasonality":
      return t("Seasonality");
    case "Fourier":
      return "Fourier";
    // IntersectType
    case "Level":
      return t("Level");
    case "Intersect":
      return t("Base level");
    // TrendType
    case "Trend":
      return t("Trend");
    case "QuadraticTrend":
      return t("Quadratic Trend");
    case "LogarithmicTrend":
      return t("Logarithmic Trend");
    // DayOfWeek
    case "Monday":
      return t("Monday");
    case "Tuesday":
      return t("Tuesday");
    case "Wednesday":
      return t("Wednesday");
    case "Thursday":
      return t("Thursday");
    case "Friday":
      return t("Friday");
    case "Saturday":
      return t("Saturday");
    case "Sunday":
      return t("Sunday");
    // ShortDayOfWeek
    case "Mon":
      return t("Monday <short>");
    case "Tue":
      return t("Tuesday <short>");
    case "Wed":
      return t("Wednesday <short>");
    case "Thu":
      return t("Thursday <short>");
    case "Fri":
      return t("Friday <short>");
    case "Sat":
      return t("Saturday <short>");
    case "Sun":
      return t("Sunday <short>");
    // PeriodUnit
    case "D":
      return t("days", options);
    case "W":
      return t("weeks", options);
    case "M":
      return t("months", options);
    case "Q":
      return t("quarters", options);
    case "Y":
      return t("years", options);
    // uc:PeriodUnit - uppercase variant for a standalone select
    case "uc:D":
      return t("Days", options);
    case "uc:W":
      return t("Weeks", options);
    case "uc:M":
      return t("Months", options);
    case "uc:Q":
      return t("Quarters", options);
    case "uc:Y":
      return t("Years", options);
    // OutlierDetectionMethod
    case "Statistical":
      return t("Statistical");
    case "FixedBounds":
      return t("Fixed Bounds");
    case "IsolationForest":
      return t("Isolation Forest");
    // StatisticalMeasure
    case "std":
      return t("Standard deviation");
    case "iqr":
      return t("Interquartile range");
    case "ci":
      return t("Confidence interval");
    // ProfileYScale
    case "Mean":
      return t("Absolute (daily mean)");
    case "Percentage":
      return t("Relative (%)");
    // SeriesType
    case "Line":
      return t("Line (Chart)");
    case "Area":
      return t("Area");
    case "Bar":
      return t("Bar");
    case "Scatter":
      return t("scatter");
    case "Step":
      return t("step");
    case "Interval":
      return t("Interval");
    // PrognosPage
    case "modelPage":
      return t("Model");
    case "scenarioPage":
      return t("Scenario");
    // PageSection
    case "generalSection":
      return t("General settings");
    case "taskSection":
      return t("Task settings");
    case "dataSelSection":
      return t("Data selection");
    case "dataPrepSection":
      return t("Data preparation");
    case "algorithmSection":
      return t("Algorithm");
    case "preprocessingSection":
      return t("Pre-processing");
    case "postprocessingSection":
      return t("Post-processing");
    case "IFsSection":
      return t("Influencing factors");
    case "outliersSection":
      return t("Outliers");
    case "profilesSection":
      return t("Intraday profiles");
    case "advancedSection":
      return t("JSON definition");
    case "fixedModelsSection":
      return t("Run on fixed models");
    // IntradayProfileMethod
    case "uniform":
      return t("Uniform");
    case "monthly":
      return t("Monthly");
    case "quarterly":
      return t("Quarterly");
    // ProfileReferenceType
    case "Last":
      return t("before last data point");
    case "Reference":
      return t("between start date and end date");
    case "ByMonth":
      return "by_month"; // this should not occur in the UI
    case "ByQuarter":
      return "by_quarter"; // this should not occur in the UI
    // ArimaType - not translated on purpose
    case "AR":
      return "Auto-Regressive (ARIMA)";
    case "I":
      return "Integrated (ARIMA)";
    case "MA":
      return "Moving Average (ARIMA)";
    // CategoricalType
    case "DayInMonth":
      return t("Day of the month");
    case "DayInWeek":
      return t("Day of the week");
    case "DayInYear":
      return t("Day of the year");
    case "MonthInYear":
      return t("Month");
    case "Quarters":
      return t("Quarter");
    case "Year":
      return t("Year");
    // CorrectionType
    case "CorrectionType:Absolute":
      return t("Add value");
    case "CorrectionType:Relative":
      return t("Add percentage");
    case "CorrectionType:Overwrite":
      return t("Overwrite");
    case "CorrectionType:ReplaceTrend":
      return t("Replace trend");
    case "CorrectionType:ReplaceIntersect":
      return t("Replace base level");
    // DataImportConfigDependencyType
    case "DataImportConfigDependencyType:Chart":
      return t("{{count}} charts", options);
    case "DataImportConfigDependencyType:RunConfig":
      return t("{{count}} scenarios", options);
    case "DataImportConfigDependencyType:RunResult":
      return t("{{count}} results", options);
    case "DataImportConfigDependencyType:BusinessHourSet":
      return t("{{count}} business hours rulesets", options);
    case "DataImportConfigDependencyType:InfluencingFactor":
      return t("{{count}} outliers and influencing factors", options);
    // ViewMode
    case "ViewMode:all":
      return t("All planning areas");
    case "ViewMode:aggregates":
      return t("Aggregates");
    case "ViewMode:leaves":
      return t("Non-aggregates");
    // ExportFileType
    case "xlsx":
      return t("Excel (.xlsx)");
    case "csv":
      return t("Csv (.csv)");
    case "tsv":
      return t("Tsv (.tsv)");
    case "txt":
      return t("Plaintext (.txt)");
    // ExportColumnSeparator
    case ",":
      return t("Comma (,)");
    case ";":
      return t("Semicolon (;)");
    case "\t":
      return t("Tab");
    // ImportExportSeparator
    case ", ":
      return t("Comma and space (, )");
    case ":":
      return t("Colon");
    case "; ":
      return t("Semicolon and space (; )");
    case "\t,":
      return t("Tab and comma (\\t,)");
    case "\t;":
      return t("Tab and semicolon (\\t;)");
    // SolutionPhase
    case "SolutionPhase:New":
      return t("First steps");
    case "SolutionPhase:Development":
      return t("Development");
    case "SolutionPhase:Production":
      return t("Production");
    case "SolutionPhase:Archived":
      return t("Archived");
    // ImportExportZone
    case "Utc":
      return "UTC";
    case "Local":
      return t("Local");
    // ConvertValuesOption
    case "ConvertValues:None":
      return t("None");
    case "ConvertValues:NullsToZeros":
      return t("Nulls to zero");
    case "ConvertValues:ZerosToNulls":
      return t("Zeros to null");
    // AggregationFn
    case "AggregationFn:Min":
      return t("Minimum");
    case "AggregationFn:Mean":
      return t("Mean");
    case "AggregationFn:Max":
      return t("Maximum");
    case "AggregationFn:Percentile":
      return t("xth percentile");
    // TransformationMethod
    case "TransformationMethod:Add":
      return t("Add {{x}}", options);
    case "TransformationMethod:Subtract":
      return t("Subtract {{x}}", options);
    case "TransformationMethod:Multiply":
      return t("Multiply by {{x}}", options);
    case "TransformationMethod:Divide":
      return t("Divide by {{x}}", options);
    case "TransformationMethod:Min":
      return t("Minimal value {{x}}", options);
    case "TransformationMethod:Max":
      return t("Maximal value {{x}}", options);
    case "TransformationMethod:RoundDown":
      return t("Round down");
    case "TransformationMethod:RoundUp":
      return t("Round up");
    // AggregationMethod
    case "AggregationMethod:Min":
      return t("Minimum value");
    case "AggregationMethod:Max":
      return t("Maximum value");
    case "AggregationMethod:Sum":
      return t("Sum");
    // DemandVariableType
    case "DemandVariableType:Boolean":
      return t("Yes / No");
    case "DemandVariableType:Date":
      return t("Date");
    case "DemandVariableType:Number":
      return t("Number");
    // Invalid
    case "Invalid":
      return t("unknown");
  }
}
