import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";

import AlertDialog from "../apps/PrognosAI/components/dialogs/AlertDialog";

interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}
interface ErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    console.error(error, errorInfo);
  }

  render() {
    const { children, fallback } = this.props;
    const { hasError } = this.state;

    if (!hasError) {
      return children;
    }

    if (fallback) {
      return fallback;
    }

    return <ErrorDialog />;
  }
}

function ErrorDialog(): JSX.Element {
  const { t } = useTranslation();

  return (
    <AlertDialog
      id="errorBoundary-alertDialog"
      type="error"
      open
      title={t("Something went wrong")}
      closeLabel={t("Reload page")}
      onClose={() => location.reload()}
    >
      {t("Please reload the page and try again.")}
    </AlertDialog>
  );
}
