import { z } from "zod";

import { DateZod } from "./primitives";

export const DataCollectionZod = z.object({
  dataCollectionId: z.number(),
  name: z.string(),
  solutionId: z.number(),
  firstTimestamp: DateZod.nullable(),
  lastTimestamp: DateZod.nullable(),
});
export type DataCollection = z.infer<typeof DataCollectionZod>;

export const DataCollectionUsedIdentifiersZod = DataCollectionZod.pick({
  dataCollectionId: true,
  name: true,
});
export type DataCollectionUsedIdentifiers = z.infer<
  typeof DataCollectionUsedIdentifiersZod
>;
