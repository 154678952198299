import React, { type JSX } from "react";

import SkeletonCard from "../../../../../components/skeletons/SkeletonCard";
import SkeletonHero from "../../../../../components/skeletons/SkeletonHero";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import { blockTypes } from "../../../models/canvasBlock";

export default function CanvasSkeleton(): JSX.Element {
  return (
    <>
      <SkeletonWrapper noLoading>
        <SkeletonHero />
      </SkeletonWrapper>
      <div className="w-full grow flex space-x-4">
        <SkeletonWrapper noLoading>
          <div className="space-y-4">
            {blockTypes.map((type) => (
              <SkeletonCard
                key={type}
                style={{
                  width: 144,
                  height: 52,
                  borderLeftWidth: 4,
                  borderRightWidth: 4,
                }}
              ></SkeletonCard>
            ))}
          </div>
        </SkeletonWrapper>
        <SkeletonWrapper className="grow">
          <span></span>
        </SkeletonWrapper>
      </div>
    </>
  );
}
