import React from "react";
import { Navigate, RouteObject } from "react-router";

import { z } from "zod";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getBusinessMapPath } from "./businessMap";

const Variables = lazyWithRetry(() => import("../pages/Variables/Variables"));
const VariableDetail = lazyWithRetry(
  () => import("../pages/VariableDetail/VariableDetail")
);
const General = lazyWithRetry(
  () => import("../pages/VariableDetail/sections/General")
);
const Usage = lazyWithRetry(
  () => import("../pages/VariableDetail/sections/Usage")
);

const VARIABLES_PATH = "variables";

const variablePages = ["general", "usage"] as const;
export const VariablePageZod = z.enum(variablePages);
export type VariablePage = z.infer<typeof VariablePageZod>;

const PageEnum = VariablePageZod.Enum;

export const variablesRoute: RouteObject = {
  path: VARIABLES_PATH,
  children: [
    { index: true, element: <Variables /> },
    {
      path: ":demandVariableId",
      element: <VariableDetail />,
      children: [
        { index: true, element: <Navigate to={PageEnum.general} replace /> },
        { path: PageEnum.general, element: <General /> },
        { path: PageEnum.usage, element: <Usage /> },
      ],
    },
  ],
};

export function getVariablesPath(
  solutionId: string | number,
  businessMapId: string | number
): string {
  return `${getBusinessMapPath(solutionId, businessMapId)}/${VARIABLES_PATH}`;
}

export function getVariableDetailPath(
  solutionId: string | number,
  businessMapId: string | number,
  demandVariableId: string | number
): string {
  return `${getVariablesPath(solutionId, businessMapId)}/${demandVariableId}`;
}
