import React, { type JSX } from "react";

import classNames from "classnames";

import SkeletonWrapper from "../../../../components/skeletons/SkeletonWrapper";
import { ResultSegmentSparkline } from "../../models/result";
import PrognosPlot from "./PrognosPlot";

interface SparklineProps {
  id: string;
  data: ResultSegmentSparkline | undefined;
  isLoading: boolean;
  className?: string;
}

export default function Sparkline(props: SparklineProps): JSX.Element | null {
  const { id, data, isLoading, className } = props;
  if (isLoading) {
    return (
      <div
        className={classNames(className, "flex items-center justify-center")}
      >
        <SkeletonWrapper spinnerSize={20}>
          <div
            className={classNames("bg-gray-100 rounded-md", className)}
          ></div>
        </SkeletonWrapper>
      </div>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <PrognosPlot
      id={id}
      data={[
        {
          type: "scatter",
          x: data.data.map((e) => e.x),
          y: data.data.map((e) => e.yHistory ?? null),
          line: {
            color: "#4169e1",
          },
        },
        {
          type: "scatter",
          x: data.data.map((e) => e.x),
          y: data.data.map((e) => e.yForecast ?? null),
          line: {
            color: "#32cd32",
          },
        },
      ]}
      layout={{
        autosize: true,
        margin: { t: 0, b: 0, l: 0, r: 0 },
        hovermode: false,
        dragmode: false,
        xaxis: {
          visible: false,
        },
        yaxis: {
          visible: false,
        },
        showlegend: false,
      }}
      config={{ displayModeBar: false, displaylogo: false }}
      className={classNames("sparkline-plot", className)}
    ></PrognosPlot>
  );
}
