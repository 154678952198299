import React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useQueryClient } from "@tanstack/react-query";

import { modelRunConfigsQuery } from "../../../api/modelRunConfigs";
import { lastSegmentForecastQuery, resultsQuery } from "../../../api/results";
import { runPartitionsQuery } from "../../../api/runFixedModels";
import {
  activeModelTasksQuery,
  activeRunTasksQuery,
  activeSolutionRunTasksQuery,
  failedOrFinishedFixedModelAssignmentTasksQuery,
} from "../../../api/tasks";
import { Message } from "../../../models/message";
import {
  ModelRunTaskZod,
  TaskStatus,
  finalTaskStates,
} from "../../../models/task";

export const useRunMessagesCallback = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const callback = React.useCallback(
    (msg: Message) => {
      const parser = ModelRunTaskZod.safeParse(msg.eventArgs);
      if (!parser.success) {
        return;
      }

      const runTask = parser.data;

      const solutionId = runTask.solutionId.toString();
      const modelIds = runTask.modelIds;
      const runConfigId = runTask.runConfigId.toString();

      const alwaysInvalidate = [
        resultsQuery(solutionId),
        {
          queryKey: lastSegmentForecastQuery(
            solutionId,
            null,
            null
          ).queryKey.slice(0, 2),
        },
        activeSolutionRunTasksQuery(solutionId),
        activeRunTasksQuery(runConfigId),
        ...modelIds.map((modelId) => activeModelTasksQuery(modelId)),
      ];

      for (const query of alwaysInvalidate) {
        queryClient.invalidateQueries(query);
      }

      if ((finalTaskStates as readonly TaskStatus[]).includes(runTask.status)) {
        if (runTask.taskName === "ModelAutoSelection") {
          queryClient.invalidateQueries(runPartitionsQuery(runConfigId));
          queryClient.invalidateQueries(
            failedOrFinishedFixedModelAssignmentTasksQuery(runConfigId)
          );
        }

        queryClient.invalidateQueries(modelRunConfigsQuery(solutionId));
      }

      // TODO link the runs and specify their names if possible
      if (runTask.status === "Failed") {
        if (runTask.taskName === "ModelAutoSelection") {
          return t("The model assignment failed to execute. Please try again.");
        }
        return toast.error(
          t(
            "The run failed to execute. Please check for warnings and errors and try again."
          )
        );
      }
      if (runTask.status === "Canceled") {
        if (runTask.taskName === "ModelAutoSelection") {
          return t("The automated model assignment was canceled.");
        }
        return toast.success(t("The run was canceled successfully."));
      }

      if (runTask.status === "Finished") {
        if (runTask.taskName === "ModelAutoSelection") {
          return t("The automated model assignment has finished.");
        }
        return toast.success(t("The run was finished successfully."));
      }
    },
    [queryClient, t]
  );

  return callback;
};
