import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getShiftPlanPath } from "./shiftsAndStaffing";

const ShiftTypes = lazyWithRetry(
  () => import("../../ShiftsAndStaffing/pages/ShiftTypes/ShiftTypes")
);

const ShiftType = lazyWithRetry(
  () => import("../../ShiftsAndStaffing/pages/ShiftType/ShiftType")
);

const SHIFT_TYPES_PATH = "shiftTypes";

export const shiftTypesRoute: RouteObject = {
  path: SHIFT_TYPES_PATH,
  children: [
    { index: true, element: <ShiftTypes /> },
    { path: ":shiftTypeId", element: <ShiftType /> },
  ],
};

export function getShiftTypesPath(
  solutionId: string | number,
  shiftPlanId: string | number
): string {
  return `${getShiftPlanPath(solutionId, shiftPlanId)}/${SHIFT_TYPES_PATH}`;
}

export function getShiftTypePath(
  solutionId: string | number,
  shiftPlanId: string | number,
  shiftTypeId: string | number
): string {
  return `${getShiftTypesPath(solutionId, shiftPlanId)}/${shiftTypeId}`;
}
