import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";

import { useQuery } from "@tanstack/react-query";
import { TFunction } from "i18next";

import {
  solutionUsedIdentifiersQuery,
  useDeleteSolution,
  useEditSolution,
  useSolution,
} from "../../api/solutions";
import EntityExportButton from "../../apps/DataStore/pages/BusinessHours/components/EntityExportButton";
import NarrowFormCard from "../../apps/PrognosAI/components/narrowform/NarrowFormCard";
import NarrowFormItem from "../../apps/PrognosAI/components/narrowform/NarrowFormItem";
import { hasData } from "../../apps/PrognosAI/functions/solution";
import useAutosave from "../../apps/PrognosAI/hooks/useAutosave";
import { tenum } from "../../apps/PrognosAI/services/translationMappings";
import AutosaveIndicator from "../../components/AutosaveIndicator";
import Hero from "../../components/Hero";
import NarrowForm from "../../components/NarrowForm";
import Button from "../../components/buttons/Button";
import ConfirmationButton from "../../components/buttons/ConfirmationButton";
import DebouncedTextField from "../../components/form/DebouncedTextField";
import Select from "../../components/form/Select";
import TimezonePicker from "../../components/form/TimezonePicker";
import SettingsSkeleton from "../../components/skeletons/SettingsSkeleton";
import { SolutionUsedIdentifiers, solutionPhases } from "../../models/solution";
import ErrorPage from "../ErrorPage/ErrorPage";
import HistoryExportButton from "./components/HistoryExportButton";

export default function SolutionSettings(): JSX.Element {
  const { data: otherSolutions, ...otherSolutionsRes } = useQuery(
    solutionUsedIdentifiersQuery()
  );

  const [{ data: originalSolution, ...solutionRes }, solutionId] =
    useSolution();

  const editSolution = useEditSolution(solutionId);
  const autosave = useAutosave(originalSolution, editSolution.mutateAsync);
  const { entity: solution, handleChange, status } = autosave;

  const deleteSolution = useDeleteSolution(solutionId);

  const { t } = useTranslation();

  if (otherSolutionsRes.isError || solutionRes.isError) {
    return (
      <ErrorPage
        error={otherSolutionsRes.error ?? solutionRes.error}
        entity="Solution"
      />
    );
  }

  if (
    otherSolutionsRes.isLoading ||
    solutionRes.isLoading ||
    !otherSolutions ||
    !solution
  ) {
    return <SettingsSkeleton />;
  }

  const solutionHasData = hasData(solution);

  return (
    <>
      <Hero
        path={[{ label: t("Project Settings"), address: `.` }]}
        actions={<AutosaveIndicator status={status} />}
      >
        {t("Project Settings")}
      </Hero>
      <NarrowForm>
        <NarrowFormCard title={t("General Settings")}>
          <NarrowFormItem label={t("Name")} htmlFor="solution_name">
            <DebouncedTextField
              id="solution_name"
              autoFocus
              value={solution.name}
              onChange={(name) => handleChange({ name })}
              delay={0}
              generateError={(newName) =>
                generateSolutionNameError(
                  solution.solutionId,
                  newName,
                  otherSolutions,
                  t
                )
              }
            />
          </NarrowFormItem>
          <NarrowFormItem
            label={t("Time zone")}
            htmlFor="timezone"
            isDefault={
              solution.timezone ===
              Intl.DateTimeFormat().resolvedOptions().timeZone
            }
          >
            <TimezonePicker
              id="timezone"
              value={solution.timezone}
              disabled={solutionHasData}
              info={
                solutionHasData
                  ? t(
                      "The time zone cannot be changed if the project already contains data."
                    )
                  : undefined
              }
              onChange={(timezone) => handleChange({ timezone })}
            />
          </NarrowFormItem>
          <NarrowFormItem
            label={t("Project stage")}
            htmlFor="phaseSelect"
            isDefault={solution.phase === "Production"}
          >
            <Select
              id="phaseSelect"
              options={solutionPhases.map((key) => ({
                key,
                label: tenum(`SolutionPhase:${key}`, t),
              }))}
              value={{
                key: solution.phase,
                label: tenum(`SolutionPhase:${solution.phase}`, t),
              }}
              onChange={({ key }) => handleChange({ phase: key })}
            />
          </NarrowFormItem>
          <NarrowFormItem label={t("Export")} htmlFor="exportHistory">
            <div className="flex flex-col space-y-2 w-max">
              <div className="w-full">
                <HistoryExportButton
                  className="w-full !justify-start"
                  type="standard"
                  solution={solution}
                />
              </div>
              <div className="w-full">
                <EntityExportButton
                  id="businessHoursExportButton"
                  entity="BusinessHours"
                  type="standard"
                  className="w-full !justify-start"
                />
              </div>
              <div className="w-full">
                <EntityExportButton
                  id="influencingFactorsExportButton"
                  entity="InfluencingFactors"
                  type="standard"
                  className="w-full !justify-start"
                />
              </div>
            </div>
          </NarrowFormItem>
          <NarrowFormItem
            label={t("Delete project")}
            htmlFor="deleteSolution"
            isDefault
          >
            <ConfirmationButton
              id="deleteSolution"
              dialogTitle={t("Delete project")}
              dialogQuestion={t("Are you sure you want to delete the project?")}
              onConfirm={() => deleteSolution.mutate()}
            >
              {(props) => (
                <Button {...props} variant="red">
                  {t("Delete project")}
                </Button>
              )}
            </ConfirmationButton>
          </NarrowFormItem>
        </NarrowFormCard>
      </NarrowForm>
    </>
  );
}

export const generateSolutionNameError = (
  solutionId: number | null,
  newName: string | number | readonly string[] | undefined,
  otherSolutions: SolutionUsedIdentifiers[],
  t: TFunction
) => {
  if (!newName) {
    return t("The name cannot be empty.");
  }

  const isNameUsed = otherSolutions.some(
    (s) => s.name === newName && s.solutionId !== solutionId
  );
  if (isNameUsed) {
    return t("The name is already used by a different project.");
  }

  if (typeof newName === "string" && newName.length > 256) {
    return t("The maximum name length is 256 characters.");
  }

  return undefined;
};
