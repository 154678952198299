import React, { type JSX } from "react";

import BasicLayout from "../../apps/PrognosAI/components/layouts/BasicLayout";
import Version from "../sidebar/Version";
import ListPageSkeleton from "../skeletons/ListPageSkeleton";

export default function FirstLevelLayout(): JSX.Element {
  return (
    <div className="max-w-7xl h-full m-auto flex flex-1 flex-col">
      <BasicLayout skeleton={<ListPageSkeleton showTabs />} />
      <div className="fixed bottom-0 left-0 bg-slate-100">
        <Version />
      </div>
    </div>
  );
}
