import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";

import {
  AdjustmentsVerticalIcon,
  BriefcaseIcon,
  ClockIcon,
  Cog6ToothIcon,
  CubeTransparentIcon,
  HomeIcon,
  PresentationChartLineIcon,
  RectangleStackIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import { useSolution } from "../../../api/solutions";
import Sidebar, { SidebarItem } from "../../../components/sidebar/Sidebar";
import BasicSkeleton from "../../../components/skeletons/BasicSkeleton";
import ListPageSkeleton from "../../../components/skeletons/ListPageSkeleton";
import SettingsSkeleton from "../../../components/skeletons/SettingsSkeleton";
import { getTasksPath } from "../../../routes/tasks";
import { getUserSettingsPath } from "../../../routes/userSettings";
import BasicLayout from "../../PrognosAI/components/layouts/BasicLayout";
import { getConstraintsPath } from "../routes/constraints";
import { getFixedShiftsPath } from "../routes/fixedShifts";
import { getResultPath } from "../routes/result";
import { getRunConfigurationPath } from "../routes/runConfiguration";
import { getShiftTypesPath } from "../routes/shiftTypes";
import { getShiftPlanPath } from "../routes/shiftsAndStaffing";
import { getShiftsAndStaffingPath } from "../routes/shiftsAndStaffing";

interface ShiftsAndStaffingLayoutProps {
  children?: React.ReactNode;
}

export default function ShiftsAndStaffingLayout(
  props: ShiftsAndStaffingLayoutProps
) {
  const { children } = props;
  const [topItems, bottomItems] = useShiftAndStaffingItems();
  const shiftPlanItems = useShiftPlanItems();

  const skeleton = useSkeleton();

  return (
    <Sidebar
      topNavigation={[topItems, shiftPlanItems]}
      bottomNavigation={bottomItems}
    >
      <BasicLayout skeleton={skeleton}>{children}</BasicLayout>
    </Sidebar>
  );
}

function useShiftAndStaffingItems(): [SidebarItem[], SidebarItem[]] {
  const [, solutionId] = useSolution();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const topItems: SidebarItem[] = [
    {
      key: "dashboard",
      name: t("Shift plans"),
      href: getShiftsAndStaffingPath(solutionId),
      icon: HomeIcon,
      isCurrent: pathname === getShiftsAndStaffingPath(solutionId),
    },
  ];

  const bottomItems: SidebarItem[] = [
    {
      key: "userSettings",
      name: t("User settings"),
      href: getUserSettingsPath(solutionId, undefined),
      icon: UserIcon,
      isCurrent: location.pathname.startsWith(
        getUserSettingsPath(solutionId, undefined)
      ),
    },
    {
      key: "tasks",
      name: t("System tasks"),
      href: getTasksPath(solutionId, undefined),
      icon: RectangleStackIcon,
      isCurrent: location.pathname.startsWith(
        getTasksPath(solutionId, undefined)
      ),
    },
  ];

  return [topItems, bottomItems];
}

function useShiftPlanItems(): SidebarItem[] {
  const { solutionId, shiftPlanId } = useParams();
  const { pathname, search } = useLocation();
  const { t } = useTranslation();

  if (!solutionId || !shiftPlanId) {
    return [];
  }

  const items: SidebarItem[] = [
    {
      key: "settings",
      name: t("Settings"),
      href: getShiftPlanPath(solutionId, shiftPlanId) + search,
      icon: Cog6ToothIcon,
      isCurrent: pathname === getShiftPlanPath(solutionId, shiftPlanId),
    },
    {
      key: "fixedShifts",
      name: t("Fixed shifts"),
      href: getFixedShiftsPath(solutionId, shiftPlanId) + search,
      icon: ClockIcon,
      isCurrent: pathname.startsWith(
        getFixedShiftsPath(solutionId, shiftPlanId)
      ),
    },
    {
      key: "shiftTypes",
      name: t("Shift types"),
      href: getShiftTypesPath(solutionId, shiftPlanId) + search,
      icon: BriefcaseIcon,
      isCurrent: pathname.startsWith(
        getShiftTypesPath(solutionId, shiftPlanId)
      ),
    },
    {
      key: "constraints",
      name: t("Constraints"),
      href: getConstraintsPath(solutionId, shiftPlanId) + search,
      icon: CubeTransparentIcon,
      isCurrent: pathname.startsWith(
        getConstraintsPath(solutionId, shiftPlanId)
      ),
    },
    {
      key: "runConfiguration",
      name: t("Run configuration"),
      href: getRunConfigurationPath(solutionId, shiftPlanId) + search,
      icon: AdjustmentsVerticalIcon,
      isCurrent: pathname.startsWith(
        getRunConfigurationPath(solutionId, shiftPlanId)
      ),
    },
    {
      key: "result",
      name: t("Result"),
      href: getResultPath(solutionId, shiftPlanId) + search,
      icon: PresentationChartLineIcon,
      isCurrent: pathname.startsWith(getResultPath(solutionId, shiftPlanId)),
    },
  ];

  return items;
}

function useSkeleton(): JSX.Element {
  const { pathname } = useLocation();
  const { solutionId, shiftPlanId } = useParams();

  if (!solutionId) {
    return <BasicSkeleton />;
  }

  if (!shiftPlanId) {
    return <ListPageSkeleton />;
  }

  // the shift plan's first page is the settings page
  if (pathname === getShiftPlanPath(solutionId, shiftPlanId)) {
    return <SettingsSkeleton />;
  }

  // TODO other skeletons

  return <BasicSkeleton />;
}
