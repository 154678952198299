import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";

import {
  ArrowDownOnSquareIcon,
  ClockIcon,
  DocumentChartBarIcon,
  HomeIcon,
  RectangleStackIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import { useSolution } from "../../../../api/solutions";
import Sidebar, { SidebarItem } from "../../../../components/sidebar/Sidebar";
import BasicSkeleton from "../../../../components/skeletons/BasicSkeleton";
import ChartSkeleton from "../../../../components/skeletons/ChartSkeleton";
import ListPageSkeleton from "../../../../components/skeletons/ListPageSkeleton";
import SettingsSkeleton from "../../../../components/skeletons/SettingsSkeleton";
import { getTasksPath } from "../../../../routes/tasks";
import { getUserSettingsPath } from "../../../../routes/userSettings";
import BasicLayout from "../../../PrognosAI/components/layouts/BasicLayout";
import ProjectDashboardSkeleton from "../../../PrognosAI/pages/Dashboard/components/ProjectDashboardSkeleton";
import DataImportConfigMenu from "../../pages/DataImportConfig/components/DatasetMenu";
import { getBizHoursPath } from "../../routes/businessHours";
import { getDataStorePath } from "../../routes/dataStore";
import { getImportDetailPath, getImportPath } from "../../routes/import";
import { getChartDetailPath, getReviewPath } from "../../routes/review";

interface DataStoreLayoutProps {
  children?: React.ReactNode;
}

export default function DataStoreLayout({ children }: DataStoreLayoutProps) {
  const [topItems, bottomItems] = useDatasetMenuItems();

  const topNavigation = [topItems];

  const skeleton = useSkeleton();

  return (
    <Sidebar topNavigation={topNavigation} bottomNavigation={bottomItems}>
      <BasicLayout skeleton={skeleton}>{children}</BasicLayout>
    </Sidebar>
  );
}

function useSkeleton(): JSX.Element {
  const { pathname } = useLocation();
  const { solutionId, dataImportConfigId, chartId } = useParams();

  if (!solutionId) {
    return <BasicSkeleton />;
  }

  // import config detail page
  if (
    dataImportConfigId &&
    pathname.startsWith(getImportDetailPath(solutionId, dataImportConfigId))
  ) {
    return (
      <SettingsSkeleton menu={<DataImportConfigMenu dataset={undefined} />} />
    );
  }
  // data imports
  if (pathname.startsWith(getImportPath(solutionId))) {
    return <ListPageSkeleton />;
  }

  // chart detail page
  if (chartId && pathname.startsWith(getChartDetailPath(solutionId, chartId))) {
    return <ChartSkeleton />;
  }
  // review page
  if (pathname.startsWith(getReviewPath(solutionId))) {
    return <ListPageSkeleton showTabs />;
  }

  // business hours
  if (pathname.startsWith(getBizHoursPath(solutionId))) {
    return <ListPageSkeleton showTabs />;
  }

  // data store dashboard
  if (pathname.startsWith(getDataStorePath(solutionId))) {
    return <ProjectDashboardSkeleton />;
  }

  return <BasicSkeleton />;
}

function useDatasetMenuItems(): [SidebarItem[], SidebarItem[]] {
  const [, solutionId] = useSolution();
  const location = useLocation();
  const { t } = useTranslation();

  const topItems = [
    {
      key: "dashboard",
      name: t("Dashboard"),
      href: getDataStorePath(solutionId),
      icon: HomeIcon,
      isCurrent: location.pathname === getDataStorePath(solutionId),
    },
    {
      key: "import",
      name: t("Data import"),
      href: getImportPath(solutionId),
      icon: ArrowDownOnSquareIcon,
      isCurrent: location.pathname.startsWith(getImportPath(solutionId)),
    },
    {
      key: "review",
      name: t("Review"),
      href: getReviewPath(solutionId),
      icon: DocumentChartBarIcon,
      isCurrent: location.pathname.startsWith(getReviewPath(solutionId)),
    },
    {
      key: "businessHours",
      name: t("Business Hours"),
      href: getBizHoursPath(solutionId),
      icon: ClockIcon,
      isCurrent: location.pathname.startsWith(getBizHoursPath(solutionId)),
    },
  ];

  const bottomItems: SidebarItem[] = [
    {
      key: "userSettings",
      name: t("User settings"),
      href: getUserSettingsPath(solutionId, undefined),
      icon: UserIcon,
      isCurrent: location.pathname.startsWith(
        getUserSettingsPath(solutionId, undefined)
      ),
    },
    {
      key: "tasks",
      name: t("System tasks"),
      href: getTasksPath(solutionId, undefined),
      icon: RectangleStackIcon,
      isCurrent: location.pathname.startsWith(
        getTasksPath(solutionId, undefined)
      ),
    },
  ];

  return [topItems, bottomItems];
}
