import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import {
  QueryClient,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import api, { EndpointOptions, getQueryString } from "../../../api";
import {
  BusinessDay,
  BusinessDayZod,
  BusinessRuleSet,
  BusinessRuleSetZod,
  DefaultBusinessHours,
  DefaultBusinessHoursZod,
  TimeInterval,
  TimeIntervalZod,
} from "../../DataStore/models/businessHours";
import { PaginatedResponse, paginatedResponse } from "../models/response";
import { partitionBusinessHoursQuery, partitionQuery } from "./partitions";

const BIZ_RULE_SETS_API = "/Datastore/BusinessRuleSets";
const BIZ_DAYS_API = "/Datastore/BusinessDays";
const TIME_IVS_API = "/Datastore/TimeIntervals";

async function getBusinessRuleSets(
  solutionId: string,
  options: EndpointOptions = {}
): Promise<PaginatedResponse<BusinessRuleSet[]>> {
  const query = getQueryString(options);
  return paginatedResponse(BusinessRuleSetZod.array()).parse(
    (
      await api.get(
        `/Datastore/Solutions/${solutionId}/BusinessRuleSets?${query}`
      )
    ).data
  );
}

export const businessRuleSetsQuery = (
  solutionId: string,
  options?: EndpointOptions
) => ({
  queryKey: ["businessRuleSets", solutionId, options],
  queryFn: () => getBusinessRuleSets(solutionId, options),
});

async function getBusinessRuleSet(
  businessRuleSetId: string | number
): Promise<BusinessRuleSet> {
  return BusinessRuleSetZod.parse(
    (await api.get(`${BIZ_RULE_SETS_API}/${businessRuleSetId}`)).data
  );
}

export const businessRuleSetQuery = (businessRuleSetId: string | number) => ({
  queryKey: ["businessRuleSet", businessRuleSetId.toString()],
  queryFn: () => getBusinessRuleSet(businessRuleSetId),
});

async function getDefaultBusinessHours(
  solutionId: string
): Promise<DefaultBusinessHours> {
  return DefaultBusinessHoursZod.parse(
    (await api.get(`/Datastore/Solutions/${solutionId}/DefaultBusinessHours`))
      .data
  );
}

export const defaultBusinessHoursQuery = (solutionId: string) => ({
  queryKey: ["defaultBusinessHours", solutionId],
  queryFn: () => getDefaultBusinessHours(solutionId),
});

const invalidateBusinessHoursCache = (
  queryClient: QueryClient,
  ruleSet: BusinessRuleSet,
  solutionId: string
) => {
  queryClient.invalidateQueries(businessRuleSetsQuery(solutionId));
  queryClient.invalidateQueries(
    businessRuleSetQuery(ruleSet.businessRuleSetId)
  );
  if (ruleSet.partitionId) {
    queryClient.invalidateQueries(partitionQuery(ruleSet.partitionId));
    queryClient.invalidateQueries(partitionBusinessHoursQuery(solutionId));
  }
  if (ruleSet.isDataCollectionDefault) {
    queryClient.invalidateQueries(defaultBusinessHoursQuery(solutionId));
  }
};

async function createBusinessRuleSet(
  solutionId: string,
  ruleSet: Omit<BusinessRuleSet, "businessRuleSetId">
) {
  return BusinessRuleSetZod.parse(
    (
      await api.post(
        `/Datastore/Solutions/${solutionId}/BusinessRuleSets`,
        ruleSet
      )
    ).data
  );
}

export const useCreateBusinessRuleSet = (solutionId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (ruleSet: Omit<BusinessRuleSet, "businessRuleSetId">) =>
      createBusinessRuleSet(solutionId, ruleSet),
    onSuccess: (ruleSet) => {
      invalidateBusinessHoursCache(queryClient, ruleSet, solutionId);
    },
    onError: () => {
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};

async function editBusinessRuleSet(
  businessRuleSetId: string,
  patch: Partial<BusinessRuleSet>
) {
  return BusinessRuleSetZod.parse(
    (await api.patch(`${BIZ_RULE_SETS_API}/${businessRuleSetId}`, patch)).data
  );
}

export const useEditBusinessRuleSet = (
  solutionId: string,
  businessRuleSetId: string
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (businessRuleSet: Partial<BusinessRuleSet>) =>
      editBusinessRuleSet(businessRuleSetId, businessRuleSet),
    onSuccess: (newRuleSet) => {
      invalidateBusinessHoursCache(queryClient, newRuleSet, solutionId);
      queryClient.setQueryData(
        ["businessRuleSet", businessRuleSetId],
        newRuleSet
      );
    },
    onError: () => {
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};

async function deleteBusinessRuleSet(businessRuleSetId: string | number) {
  return api.delete(`${BIZ_RULE_SETS_API}/${businessRuleSetId}`);
}

export const useDeleteBusinessRuleSet = (solutionId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (ruleSet: BusinessRuleSet) =>
      deleteBusinessRuleSet(ruleSet.businessRuleSetId),
    onSuccess: (_, ruleSet) => {
      invalidateBusinessHoursCache(queryClient, ruleSet, solutionId);
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};

async function createBusinessDay(
  businessRuleSetId: string | number,
  businessDay: Omit<BusinessDay, "businessDayId">
) {
  return BusinessDayZod.parse(
    (
      await api.post(
        `${BIZ_RULE_SETS_API}/${businessRuleSetId}/BusinessDays`,
        businessDay
      )
    ).data
  );
}

export const useCreateBusinessDay = (
  solutionId: string,
  ruleSet: BusinessRuleSet
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (businessDay: Omit<BusinessDay, "businessDayId">) =>
      createBusinessDay(ruleSet.businessRuleSetId, businessDay),
    onSuccess: () => {
      invalidateBusinessHoursCache(queryClient, ruleSet, solutionId);
    },
    onError: () => {
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};

async function editBusinessDay(businessDay: BusinessDay) {
  return BusinessDayZod.parse(
    (
      await api.patch(
        `${BIZ_DAYS_API}/${businessDay.businessDayId}`,
        businessDay
      )
    ).data
  );
}

export const useEditBusinessDay = (
  solutionId: string,
  ruleSet: BusinessRuleSet
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (businessDay: BusinessDay) => editBusinessDay(businessDay),
    onSuccess: () => {
      invalidateBusinessHoursCache(queryClient, ruleSet, solutionId);
    },
    onError: () => {
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};

async function deleteBusinessDay(businessDayId: string | number) {
  return api.delete(`${BIZ_DAYS_API}/${businessDayId}`);
}

export const useDeleteBusinessDay = (
  solutionId: string,
  ruleSet: BusinessRuleSet
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: deleteBusinessDay,
    onSuccess: () => {
      invalidateBusinessHoursCache(queryClient, ruleSet, solutionId);
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};

async function createTimeInterval(
  businessDayId: string | number,
  timeInterval: Omit<TimeInterval, "timeIntervalId">
) {
  return TimeIntervalZod.parse(
    (
      await api.post(
        `${BIZ_DAYS_API}/${businessDayId}/TimeIntervals`,
        timeInterval
      )
    ).data
  );
}

type CreateTimeIntervalProps = {
  timeInterval: Omit<TimeInterval, "timeIntervalId">;
  businessDayId: string | number;
};

export const useCreateTimeInterval = (
  solutionId: string,
  ruleSet: BusinessRuleSet
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({ timeInterval, businessDayId }: CreateTimeIntervalProps) =>
      createTimeInterval(businessDayId, timeInterval),
    onSuccess: () => {
      invalidateBusinessHoursCache(queryClient, ruleSet, solutionId);
    },
    onError: () => {
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};

async function editTimeInterval(timeInterval: TimeInterval) {
  return TimeIntervalZod.parse(
    (
      await api.patch(
        `${TIME_IVS_API}/${timeInterval.timeIntervalId}`,
        timeInterval
      )
    ).data
  );
}

export const useEditTimeInterval = (
  solutionId: string,
  ruleSet: BusinessRuleSet
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: editTimeInterval,
    onSuccess: () => {
      invalidateBusinessHoursCache(queryClient, ruleSet, solutionId);
    },
    onError: () => {
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};

async function deleteTimeInterval(timeIntervalId: string | number) {
  return api.delete(`${TIME_IVS_API}/${timeIntervalId}`);
}

export const useDeleteTimeInterval = (
  solutionId: string,
  ruleSet: BusinessRuleSet
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: deleteTimeInterval,
    onSuccess: () => {
      invalidateBusinessHoursCache(queryClient, ruleSet, solutionId);
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};
