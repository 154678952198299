import React, { type JSX } from "react";
import { Navigate } from "react-router";

import { useSolution } from "../../../api/solutions";
import {
  Aggregate,
  compareScales,
  getMinScale,
} from "../../../models/aggregate";
import { useAnalysis } from "../api/analysis";
import { getOverviewPath } from "../routes/overview";

interface AnalysisMinScaleProps {
  scaleTooHigh: Aggregate;
  children: React.ReactNode;
}

export default function AnalysisMinScale(
  props: AnalysisMinScaleProps
): JSX.Element {
  const { scaleTooHigh, children } = props;

  const [{ data: solution }, solutionId] = useSolution();
  const [, analysisId] = useAnalysis();

  if (!solution) {
    return <>{children}</>;
  }

  const minScale = getMinScale(solution.aggregates);

  if (minScale && compareScales(minScale, ">=", scaleTooHigh)) {
    return <Navigate to={getOverviewPath(solutionId, analysisId)} />;
  }

  return <>{children}</>;
}
