import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";
import { RouterProvider, createBrowserRouter } from "react-router";
import "react-tooltip/dist/react-tooltip.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AxiosError } from "axios";

import AuthProvider from "./components/AuthProvider";
import CustomToaster from "./components/CustomToaster";
import UsersnapProvider from "./components/UsersnapProvider";
import WebSocketProvider from "./components/WebSocketProvider";
import routes from "./routes";
import { GlobalStateProvider } from "./services/globalState";

// https://tkdodo.eu/blog/react-query-error-handling
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (error instanceof AxiosError) {
          if (error.response?.status === 403) {
            return false;
          }
          if (error.response?.status === 404) {
            return false;
          }
        }
        if (failureCount > 3) {
          return false;
        }

        return true;
      },
      // 30s, might have to change this in future
      staleTime: 1000 * 30,
    },
  },
});

const router = createBrowserRouter(routes);

export default function AppSuite(): JSX.Element {
  const { i18n } = useTranslation();

  document.documentElement.lang = i18n.language;

  return (
    <GlobalStateProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <UsersnapProvider>
            <WebSocketProvider>
              <React.Suspense>
                <CustomToaster />
                <RouterProvider router={router} />
                {/* <ReactQueryDevtools position="bottom-left" /> */}
              </React.Suspense>
            </WebSocketProvider>
          </UsersnapProvider>
        </AuthProvider>
      </QueryClientProvider>
    </GlobalStateProvider>
  );
}
