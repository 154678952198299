// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-cell {
  line-height: 35px;
  padding: 0;
  overflow: visible;
}

.filter-cell > div:last-child {
  line-height: 34px;
}

.filter-cell > div {
  padding-block: 0;
  padding-inline: 8px;
}

.filter-cell > div:first-child {
  border-block-end: 1px solid var(--rdg-border-color);
}

.rdg {
  border: 1px solid var(--rdg-border-color);
}

.rdg-cell {
  border-block-end: 1px solid var(--rdg-border-color);
  border-inline-end: 1px solid var(--rdg-border-color);
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/DataGrid.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mDAAmD;AACrD;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,mDAAmD;EACnD,oDAAoD;AACtD","sourcesContent":[".filter-cell {\n  line-height: 35px;\n  padding: 0;\n  overflow: visible;\n}\n\n.filter-cell > div:last-child {\n  line-height: 34px;\n}\n\n.filter-cell > div {\n  padding-block: 0;\n  padding-inline: 8px;\n}\n\n.filter-cell > div:first-child {\n  border-block-end: 1px solid var(--rdg-border-color);\n}\n\n.rdg {\n  border: 1px solid var(--rdg-border-color);\n}\n\n.rdg-cell {\n  border-block-end: 1px solid var(--rdg-border-color);\n  border-inline-end: 1px solid var(--rdg-border-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
