import React, { type JSX } from "react";

import ConfirmationDialog from "../../apps/PrognosAI/components/dialogs/ConfirmationDialog";

interface ConfirmationButtonProps {
  id: string;
  dialogTitle: string;
  dialogQuestion: string;
  onConfirm(): void;
  children: (props: { id: string; onClick(): void }) => JSX.Element;
}

export default function ConfirmationButton(
  props: ConfirmationButtonProps
): JSX.Element {
  const { id, dialogTitle, dialogQuestion, onConfirm, children } = props;

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleButtonClick = () => setOpenDialog(true);

  const handleClose = () => setOpenDialog(false);

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  return (
    <>
      {children({ id, onClick: handleButtonClick })}
      <ConfirmationDialog
        id={`${id}-confirmDialog`}
        title={dialogTitle}
        open={openDialog}
        onClose={handleClose}
        onConfirm={handleConfirm}
      >
        {dialogQuestion}
      </ConfirmationDialog>
    </>
  );
}
