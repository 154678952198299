import { z } from "zod";

export const RunFixedModelZod = z.object({
  fixedModelId: z.number(),
  modelId: z.number(),
  runConfigId: z.number(),
  modelName: z.string(),
  modelVersion: z.string(),
  nrPartitions: z.number(),
});
export type RunFixedModel = z.infer<typeof RunFixedModelZod>;

export const RunPartitionZod = z.object({
  runConfigPartitionId: z.number(),
  runConfigId: z.number(),
  partitionId: z.number(),
  fixedModelId: z.number().nullable().optional(),
  modelId: z.number().nullable().optional(),
  fullName: z.string(),
  metricValue: z.number().nullable(),
  runtime: z.number().nullable(),
});
export type RunPartition = z.infer<typeof RunPartitionZod>;
