import { getSolutionPath } from "../../../routes/solutions";

export const SHIFTS_AND_STAFFING_PATH = "shiftsAndStaffing";

export function getShiftsAndStaffingPath(solutionId: string | number): string {
  return `${getSolutionPath(solutionId)}/${SHIFTS_AND_STAFFING_PATH}`;
}
export function getShiftPlanPath(
  solutionId: string | number,
  shiftPlanId: string | number
): string {
  return `${getShiftsAndStaffingPath(solutionId)}/${shiftPlanId}`;
}
