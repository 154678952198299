import React, { type JSX } from "react";

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: (children: React.ReactNode) => JSX.Element;
  children: JSX.Element;
}

export default function ConditionalWrapper(
  props: ConditionalWrapperProps
): JSX.Element {
  return props.condition ? props.wrapper(props.children) : props.children;
}
