import React from "react";

const DEFAULT_DELAY = 3_000;

export type EntityErrors<T> = {
  [P in keyof T]?: string;
};

type AutosaveOptions<T> = {
  delay?: number;
  errorGenerator?(patch: Partial<T>): EntityErrors<T>;
};

/**
 * A custom hook for performing a safe and delayed autosave.
 *
 * The hook debounces the changes being set to the `waitingPatch` (via the `handleChange` calls)
 * and safely sends them to the API using the `callback` function. In case the user leaves the page,
 * the patch is fired immediately. In case the user wants to leave Prognos AI completely, they are
 * warned with the native window.onbeforeunload prompt.
 *
 * Reasoning behind this:
 *
 * - all PATCH calls are be debounced significantly, 3 seconds is imo perfect
 * - no patch can fire before the previous one was finished: in the unlikely case the previous patch took longer than these 3s, the next patch is further debounced
 * - if the user goes to a different Prognos AI page in those 3s, the patch is fired immediately (not to lose any changes)
 * - if the user tries to leave Prognos AI in those 3s (or with a pending patch), they get the native browser warning "Are you sure you want to leave?"
 * - OUTSIDE OF THIS HOOK: to prevent flickering and those weird value changes, the updates are optimistic and the UI handles them on its own by merging the following data:
 *   - the base is the actual entity data fetched from the backend;
 *   - on top of that, the pending patch data is applied (merged object);
 *   - on top of that, the waiting (debounced) patch data is applied;
 *   - the result is displayed to the user.
 *
 * @param originalEntity the original unmodified entity object
 * @param callback the API call function
 * @param options the autosave options and additional features
 * @returns objects containing the modified entity object, the change callback, and the autosave status
 */
export default function useAutosave<T, U extends T | undefined>(
  originalEntity: U,
  callback: (patch: Partial<T>) => Promise<unknown>,
  options?: AutosaveOptions<T>
) {
  const originalDelay = options?.delay ?? DEFAULT_DELAY;
  const [delay, setDelay] = React.useState(originalDelay);
  const { errorGenerator } = options ?? {};
  const callbackRef =
    React.useRef<(patch: Partial<T>) => Promise<unknown>>(undefined);
  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const [waitingPatch, setWaitingPatch] = React.useState<Partial<T> | null>(
    null
  );
  const [pendingPatch, setPendingPatch] = React.useState<Partial<T> | null>(
    null
  );
  const [hasFailed, setHasFailed] = React.useState(false);

  const patch = mergePatch(pendingPatch ?? {}, waitingPatch);
  const isDirty = waitingPatch !== null;
  const isSaving = pendingPatch !== null;

  const entity = originalEntity
    ? mergePatch(originalEntity, patch)
    : originalEntity;

  const errors: EntityErrors<T> =
    entity && errorGenerator ? errorGenerator(entity) : {};
  const isInvalid = Object.keys(errors).length > 0;

  // warn the user before leaving: there are unsaved or pending changes
  React.useEffect(() => {
    window.onbeforeunload = function () {
      if (waitingPatch || pendingPatch) {
        return "";
      }
      return;
    };

    return () => {
      window.onbeforeunload = () => {
        return;
      };
    };
  }, [waitingPatch, pendingPatch]);

  // save with delay after change was made
  React.useEffect(() => {
    async function saveChanges() {
      // not saving if the entity state is invalid
      if (isInvalid) {
        return;
      }
      // not saving if there isn't anything to save
      if (!waitingPatch || !callbackRef.current) {
        return;
      }

      // not saving if a patch is in progress
      if (pendingPatch) {
        return;
      }

      setPendingPatch(waitingPatch);
      setWaitingPatch(null);
      try {
        await callbackRef.current(waitingPatch);
        setHasFailed(false);
        setDelay(originalDelay);
      } catch (e) {
        console.error(e);
        setHasFailed(true);
        setDelay((prev) =>
          // delay next save first to 5s and then after 10s
          prev < 5_000 ? 5_000 : prev < 10_000 ? 10_000 : prev
        );
        // in case of error, return the pending patch back
        setWaitingPatch((laterCreatedPatch) =>
          laterCreatedPatch
            ? mergePatch(waitingPatch, laterCreatedPatch)
            : waitingPatch
        );
      } finally {
        setPendingPatch(null);
      }
    }

    const timeout = setTimeout(saveChanges, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [waitingPatch, callbackRef, isInvalid, delay, originalDelay]);

  // save immediately on unmount
  const waitingPatchRef = React.useRef<Partial<T> | null>(null);
  React.useEffect(() => {
    waitingPatchRef.current = waitingPatch;
  }, [waitingPatch]);
  const isInvalidRef = React.useRef(false);
  React.useEffect(() => {
    isInvalidRef.current = isInvalid;
  }, [isInvalid]);
  React.useEffect(() => {
    return () => {
      // not saving if the entity state is invalid
      if (isInvalidRef.current) {
        return;
      }
      if (waitingPatchRef.current && callbackRef.current) {
        callbackRef.current(waitingPatchRef.current);
      }
    };
  }, []);

  const status = getStatus({ isDirty, isSaving, isInvalid, hasFailed });
  const handleChange = (patch: Partial<T>) =>
    setWaitingPatch((prev) => (prev ? mergePatch(prev ?? {}, patch) : patch));

  return { entity, status, errors, handleChange };
}

export type AutosaveStatus =
  | "idle"
  | "dirty"
  | "pending"
  | "invalid"
  | "error"
  | "retrying";

type StatusBools = {
  isDirty: boolean;
  isSaving: boolean;
  isInvalid: boolean;
  hasFailed: boolean;
};

function getStatus(bools: StatusBools): AutosaveStatus {
  const { isDirty, isSaving, isInvalid, hasFailed } = bools;
  if (isSaving) {
    if (hasFailed) {
      return "retrying";
    }
    return "pending";
  }
  if (isDirty) {
    if (hasFailed) {
      return "error";
    }
    if (isInvalid) {
      return "invalid";
    }
    return "dirty";
  }

  return "idle";
}

function mergePatch<T, U extends T | Partial<T>>(
  entity: U,
  ...patches: (Partial<T> | null)[]
): U {
  if (patches.length === 0) {
    return entity;
  }

  let result = { ...entity };
  for (const patch of patches) {
    if (patch !== null) {
      result = { ...result, ...patch };
    }
  }
  return result;
}
