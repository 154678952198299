import { z } from "zod";

import { AggregateZod } from "../../../models/aggregate";
import { DateTimeZod, DateZod } from "../../../models/primitives";

export const factorSources = ["Manual", "Automatic"] as const;
export const FactorSourceZod = z.enum(factorSources);
export type FactorSource = z.infer<typeof FactorSourceZod>;

export const influencingFactorShapes = [
  "Constant",
  "Linear",
  "Triangular",
  "Tetragonal",
  "IndividualFit",
] as const;
export const InfluencingFactorShapeZod = z.enum(influencingFactorShapes);
export type InfluencingFactorShape = z.infer<typeof InfluencingFactorShapeZod>;

export const DataSegmentZod = z.object({
  measurementId: z.number(),
  partitionId: z.number(),
});
export type DataSegment = z.infer<typeof DataSegmentZod>;

export const InfluencingFactorZod = z.object({
  influencingFactorId: z.number(),
  name: z.string(),
  description: z.string(),
  functionShape: InfluencingFactorShapeZod,
  isOutlier: z.boolean(),
  source: FactorSourceZod,
  appliesToScaledDataCollection: z.boolean(),
  appliesToRunConfigs: z.number().array().catch([]),
  appliesToAlgorithmConfigs: z.number().array().catch([]),
  appliesToDataSegment: DataSegmentZod.array().catch([]),
  useRrule: z.boolean(),
  rrule: z.string().nullable().optional(),
  rruleFrom: DateZod.nullable().optional(),
  rruleTo: DateZod.nullable().optional(),
  timeScale: AggregateZod,
  numTimeSteps: z.number(),
});
export type InfluencingFactor = z.infer<typeof InfluencingFactorZod>;

export const FactorListEntryZod = InfluencingFactorZod.omit({
  description: true,
  functionShape: true,
  appliesToDataSegment: true,
}).extend({
  appliesToMeasurements: z.number().array().catch([]),
  appliesToPartitions: z.number().array().catch([]),
  appliesToModels: z.number().array().catch([]),
  minDate: DateZod.nullable(),
  maxDate: DateZod.nullable(),
  created: DateTimeZod.nullable(),
  rangesNum: z.number(),
});
export type FactorListEntry = z.infer<typeof FactorListEntryZod>;

export const InfluencingFactorRangeZod = z.object({
  influencingFactorRangeId: z.number().optional(),
  dateFrom: DateZod,
  dateTo: DateZod,
  weight: z.number(),
});
export type InfluencingFactorRange = z.infer<typeof InfluencingFactorRangeZod>;

export const InfluencingFactorDetailZod = InfluencingFactorZod.extend({
  ranges: InfluencingFactorRangeZod.array(),
});
export type InfluencingFactorDetail = z.infer<
  typeof InfluencingFactorDetailZod
>;
export type InfluencingFactorPatch = Partial<
  // ranges can't be patched: they have their own API
  Omit<InfluencingFactorDetail, "ranges">
>;

export const FactorEntityEntryZod = InfluencingFactorZod.omit({
  description: true,
  functionShape: true,
  appliesToDataSegment: true,
}).extend({
  appliesToMeasurements: z.number().array().catch([]),
  appliesToPartitions: z.number().array().catch([]),
  appliesToModels: z.number().array().catch([]),
  created: DateTimeZod.nullable(),
  ranges: InfluencingFactorRangeZod.array(),
});
export type FactorEntityEntry = z.infer<typeof FactorEntityEntryZod>;

export type GeneralFactor =
  | InfluencingFactorDetail
  | FactorListEntry
  | FactorEntityEntry;

export type GeneralFactorWithRanges = GeneralFactor & {
  ranges: InfluencingFactorRange[];
};

export const FactorUsedIdentifiersZod = InfluencingFactorZod.pick({
  influencingFactorId: true,
  name: true,
  isOutlier: true,
});
export type FactorUsedIdentifiers = z.infer<typeof FactorUsedIdentifiersZod>;
