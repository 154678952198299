import React, { type JSX } from "react";

import TableCell from "../../apps/PrognosAI/components/table/TableCell";
import TableRow from "../../apps/PrognosAI/components/table/TableRow";

interface DataTableSkeletonProps {
  size: number;
  colSpan: number;
}

export default function DataTableSkeleton(
  props: DataTableSkeletonProps
): JSX.Element {
  const { size, colSpan } = props;

  return (
    <>
      {[...Array(size)].map((_, index) => (
        <TableRow key={index} className="hover:bg-inherit">
          <TableCell className="text-center animate-pulse" colSpan={colSpan}>
            <div className="w-full h-6 bg-gray-200 shadow-none rounded-full"></div>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
}
