import { z } from "zod";

import { AggregateZod } from "../../../models/aggregate";
import {
  DateTimeZod,
  DateZod,
  PeriodUnitZod,
} from "../../../models/primitives";
import { ResultMetricZod } from "./settings";
import { TaskStatusZod, TaskSubStatusZod } from "./task";

export const runTaskTypes = ["Forecast", "Validation", "Backtest"] as const;
export const RunTaskTypeZod = z.enum(runTaskTypes);
export type RunTaskType = z.infer<typeof RunTaskTypeZod>;

export const RunZod = z.object({
  runConfigId: z.number(),
  name: z.string(),
  description: z.string().nullable().optional(),
  taskType: RunTaskTypeZod,
  lastExecuted: DateTimeZod.nullable().optional(),
});
export type Run = z.infer<typeof RunZod>;

export const partialOutliersRules = ["Always", "Never", "50%"] as const;
export const PartialOutliersRuleZod = z.enum(partialOutliersRules);
export type PartialOutliersRule = z.infer<typeof PartialOutliersRuleZod>;

export const interpolationMethods = [
  "Constant",
  "Linear",
  "Cubic",
  "RollingMean",
  "RollingMedian",
] as const;
export const InterpolationMethodZod = z.enum(interpolationMethods);
export type InterpolationMethod = z.infer<typeof InterpolationMethodZod>;

export const profileReferenceTypes = [
  "Last",
  "Reference",
  "ByMonth",
  "ByQuarter",
] as const;
export const ProfileReferenceTypeZod = z.enum(profileReferenceTypes);
export type ProfileReferenceType = z.infer<typeof ProfileReferenceTypeZod>;

export function getIntradayProfileMethod(
  type: ProfileReferenceType
): IntradayProfileMethod {
  if (type === "ByMonth") {
    return "monthly";
  }
  if (type === "ByQuarter") {
    return "quarterly";
  }

  return "uniform";
}

export const intradayProfileMethods = [
  "uniform",
  "monthly",
  "quarterly",
] as const;
export const IntradayProfileMethodZod = z.enum(intradayProfileMethods);
export type IntradayProfileMethod = z.infer<typeof IntradayProfileMethodZod>;

export const autoModelsMethods = ["Validation", "Backtest"] as const;
export const AutoModelsMethodZod = z.enum(autoModelsMethods);
export type AutoModelsMethod = z.infer<typeof AutoModelsMethodZod>;

export const RunDetailZod = RunZod.extend({
  partitions: z.number().array(),
  measurements: z.number().array(),
  aggregateLevel: AggregateZod,
  dataStart: DateTimeZod.nullable(),
  dataStartIntervalLength: z.number().nullable(),
  dataStartIntervalUnit: PeriodUnitZod.nullable(),
  dataEnd: DateTimeZod.nullable(),
  dataEndIntervalLength: z.number().nullable(),
  dataEndIntervalUnit: PeriodUnitZod.nullable(),
  removeNegative: z.boolean().catch(false),
  addNewPartitions: z.boolean().default(false),
  corrections: z.number().array().default([]),
  boxCoxTransform: z.boolean(),
  boxCoxLambda: z.number().nullable().optional(),
  differentiate: z.number().min(1).array(),
  scaleData: z.boolean(),
  ignoreZeros: z.boolean().default(false),
  interpolationMethod: InterpolationMethodZod.default("Linear"),
  fillWith: z.number().nullable(),
  windowSize: z.number().min(3),
  removeOutliers: z.boolean().default(true),
  interpolationSeasonalityLength: z.number().nullable(),
  interpolationSeasonalityUnit: PeriodUnitZod.nullable(),
  referenceType: ProfileReferenceTypeZod.default("Last"),
  historyPeriodLength: z.number(),
  historyPeriodUnit: PeriodUnitZod,
  referencePeriodStart: DateZod.nullable(),
  referencePeriodEnd: DateZod.nullable(),
  forecastEndLength: z.number().nullable(),
  forecastEndUnit: PeriodUnitZod.nullable(),
  forecastEndDate: DateZod.nullable().optional(),
  valSplit: z.number(),
  valSplitDate: DateZod.nullable().optional(),
  backtestStart: z.number(),
  backtestStartDate: DateZod.nullable().optional(),
  backtestHorizon: z.number(),
  backtestStride: z.number(),
  numSamples: z.number().min(1).default(1).catch(1),
  confidenceInterval: z.number().min(0).max(1).default(0.9).catch(0.9),
  aggregateBasedCorrection: z.boolean().default(false),
  applyPartialOutliersWhen: PartialOutliersRuleZod.default("50%"),
  ignoreBusinessTimes: z.boolean().default(false),
  excludeSolutionOutliers: z.boolean().default(false),
  excludePartitionOutliers: z.boolean().default(false),
  useReferencePlanningAreas: z.boolean().default(true),
  useReferenceUpToLength: z.number().nullable().optional(),
  useReferenceUpToUnit: PeriodUnitZod.nullable().optional(),
  useFallbackModelOnError: z.boolean().default(false),
  // fixed models:
  defaultFixedModelId: z.number().nullable().optional(),
  lastExecuted: DateTimeZod.nullable().optional(),
  lastStatus: TaskStatusZod.nullable().optional().catch(undefined),
  lastSubStatus: TaskSubStatusZod.nullable().optional().catch(undefined),
  lastResultId: z.number().nullable().optional(),
  autoModelsMethod: AutoModelsMethodZod,
  autoModelsMetric: ResultMetricZod,
  lastUsedMetric: ResultMetricZod.nullable(),
});
export type RunDetail = z.infer<typeof RunDetailZod>;

export const ResultPreviewZod = z.object({
  existingResultData: z
    .object({
      ts: DateTimeZod,
      value: z.number().nullable(),
    })
    .array(),
  newResultData: z
    .object({
      ts: DateTimeZod,
      value: z.number().nullable(),
    })
    .array(),
});
export type ResultPreview = z.infer<typeof ResultPreviewZod>;

export const RunUsedIdentifiersZod = RunZod.pick({
  runConfigId: true,
  name: true,
});
export type RunUsedIdentifiers = z.infer<typeof RunUsedIdentifiersZod>;
