import React, { type JSX } from "react";

import CircularLoading from "./CircularLoading";

interface CardProps {
  id?: string;
  children?: React.ReactNode;
  className?: string;
  paddings?: boolean;
  style?: React.CSSProperties;
}

const BASE = "rounded-md bg-white shadow";
const PADDINGS = "px-6 py-4";

export default function Card(props: CardProps): JSX.Element {
  const { id, children, className = "", paddings = true, style = {} } = props;

  return (
    <div
      id={id}
      className={`${BASE} ${paddings ? PADDINGS : ""} ${className}`}
      style={style}
    >
      {children}
    </div>
  );
}

export function LoadingCard(props: Omit<CardProps, "children">): JSX.Element {
  return (
    <Card {...props}>
      <div className="w-full flex justify-center text-gray-400 p-8">
        <CircularLoading size={24} />
      </div>
    </Card>
  );
}
