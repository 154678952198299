import React, { type JSX } from "react";

interface HeroSubtitleProps {
  children: React.ReactNode;
}

export default function HeroSubtitle(props: HeroSubtitleProps): JSX.Element {
  const { children } = props;

  return (
    <div className="text-sm font-normal text-gray-400 space-x-1">
      {children}
    </div>
  );
}

export function HeroSubtitleSeparator(): JSX.Element {
  return <span className="text-gray-200"> | </span>;
}
