import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

import Card from "../../../../../components/Card";
import Number from "../../../components/formatter/Number";
import {
  ResultDetail,
  ResultMetricKey,
  getMetricKey,
} from "../../../models/result";
import { ResultMetric, resultMetrics } from "../../../models/settings";

interface MetricsSummaryProps {
  result: ResultDetail;
  selectedMetric: ResultMetric;
  onMetricChange(metric: ResultMetric): void;
}

export default function MetricsSummary(
  props: MetricsSummaryProps
): JSX.Element {
  const { result, selectedMetric, onMetricChange } = props;

  const { t } = useTranslation();

  const { name } = getMetricInfo(selectedMetric);
  const handleMetricChange = (delta: 1 | -1) => {
    const currentIndex = resultMetrics.indexOf(selectedMetric);
    onMetricChange(
      [...resultMetrics].at(currentIndex + delta) ?? resultMetrics[0]
    );
  };

  const metricKey = getMetricKey(selectedMetric);

  const mean = result.summaryStats[metricKey]?.mean ?? "n/A";
  const sd = result.summaryStats[metricKey]?.std ?? "n/A";

  const min = result.summaryStats[metricKey]?.min ?? "n/A";
  const median = result.summaryStats[metricKey]?.median ?? "n/A";
  const max = result.summaryStats[metricKey]?.max ?? "n/A";

  return (
    <Card className="text-sm">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <button
            className="text-gray-400 hover:text-gray-500 w-6 h-6"
            onClick={() => handleMetricChange(-1)}
          >
            <ChevronLeftIcon />
          </button>
          <p className="font-semibold text-gray-500">{name}</p>
        </div>
        <button onClick={() => handleMetricChange(1)}>
          <ChevronRightIcon className="text-gray-400 hover:text-gray-500 w-6 h-6" />
        </button>
      </div>
      <div className="flex items-center px-6 gap-4 flex-wrap">
        <div className="flex flex-col items-center">
          <div className="mt-1 flex items-start space-x-1">
            <dd
              className={`font-semibold tracking-tight text-gray-900 text-3xl`}
            >
              <Number value={mean} precision={2} />
            </dd>
            {sd !== "n/A" && (
              <span className={`text-gray-500 text-sm`}>
                ±<Number value={sd} precision={2} />
              </span>
            )}
          </div>
          <dd className="text-gray-400 font-light">
            {t("mean")} {sd !== "n/A" && <>±SD</>}
          </dd>
        </div>
        <div>
          <table className="text-gray-400 font-light">
            <tbody>
              <tr>
                <td>{t("min")}</td>
                <td className="text-right text-gray-500 font-semibold pl-2">
                  <Number value={min} precision={2} tabular zeroPrecision />
                </td>
              </tr>
              <tr>
                <td>{t("median")}</td>
                <td className="text-right text-gray-500 font-semibold pl-2">
                  <Number value={median} precision={2} tabular zeroPrecision />
                </td>
              </tr>
              <tr>
                <td>{t("max")}</td>
                <td className="text-right text-gray-500 font-semibold pl-2">
                  <Number value={max} precision={2} tabular zeroPrecision />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  );
}

export function getMetricInfo(metric: ResultMetric | ResultMetricKey): {
  name: string;
  explanation?: string;
} {
  switch (metric) {
    case "RSquared":
    case "rsquare":
      return { name: "R squared" };
    case "SMAPE":
    case "smape":
      return {
        name: "sMAPE",
        explanation: "symmetric Mean Absolute Percentage Error",
      };
    case "MAE":
    case "mae":
      return { name: "MAE", explanation: "Mean Absolute Error" };
    case "MAPE":
    case "mape":
      return { name: "MAPE", explanation: "Mean Absolute Percentage Error" };
    case "MASE":
    case "mase":
      return { name: "MASE", explanation: "Mean Absolute Scaled Error" };
    case "RMSE":
    case "rmse":
      return { name: "RMSE", explanation: "Root Mean Squared Error" };
  }
}
