import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { constraintsRoute } from "./constraints";
import { fixedShiftsRoute } from "./fixedShifts";
import { resultRoute } from "./result";
import { runConfigurationRoute } from "./runConfiguration";
import { shiftTypesRoute } from "./shiftTypes";

const ShiftPlan = lazyWithRetry(
  () => import("../../ShiftsAndStaffing/pages/ShiftPlan/ShiftPlan")
);

export const shiftPlanRoute: RouteObject = {
  path: ":shiftPlanId",
  children: [
    { index: true, element: <ShiftPlan /> },
    fixedShiftsRoute,
    shiftTypesRoute,
    constraintsRoute,
    runConfigurationRoute,
    resultRoute,
  ],
};
