import React, { type JSX } from "react";

import Card from "../../../components/Card";
import HeroBreadcrumbs from "../../../components/HeroBreadcrumbs";
import SkeletonCard from "../../../components/skeletons/SkeletonCard";
import SkeletonChart from "../../../components/skeletons/SkeletonChart";
import SkeletonWrapper from "../../../components/skeletons/SkeletonWrapper";
import AdHocPartitionBanner from "./AdHocPartitionBanner";
import SettingsBar from "./SettingsBar";

interface AnalysisSkeletonProps {
  pageTitle: string | null;
  pageSettings?: React.ReactNode;
}

export default function AnalysisSkeleton(
  props: AnalysisSkeletonProps
): JSX.Element {
  const { pageTitle, pageSettings } = props;

  return (
    <>
      <HeroBreadcrumbs
        path={[
          null,
          pageTitle
            ? { label: pageTitle, address: window.location.href }
            : null,
        ]}
      />
      <div className="w-full grow flex gap-4">
        <SettingsBar pageTitle={pageTitle}>{pageSettings}</SettingsBar>
        <div className="grow h-full flex flex-col gap-4">
          <div className="px-6 pt-2">
            <AdHocPartitionBanner
              availablePartitions={[]}
              isLoading
              adHocPartition={null}
            />
          </div>
          <SkeletonWrapper className="w-full grow">
            <SkeletonCard
              className="w-full h-full flex items-center justify-center"
              style={{ height: undefined }}
            >
              <SkeletonChart className="w-1/2 h-1/2" />
            </SkeletonCard>
          </SkeletonWrapper>
          <SkeletonWrapper noLoading>
            <Card className="border-2 border-gray-200 bg-transparent shadow-none flex items-center justify-between h-[4.5rem]">
              <div className="flex space-x-2">
                <div className="h-7 bg-gray-200 rounded-full w-32"></div>
                <div className="h-7 bg-gray-200 rounded-full w-32"></div>
              </div>
              <div className="flex space-x-2">
                <div className="h-7 bg-gray-200  rounded-full w-32 hidden md:block"></div>
                <div className="h-7 border-2 border-gray-200 rounded-full w-48 hidden md:block"></div>
                <div className="h-7 bg-gray-200 rounded-full w-8"></div>
              </div>
            </Card>
          </SkeletonWrapper>
        </div>
      </div>
    </>
  );
}
