import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import api, { EndpointOptions, getQueryString } from "../../../api";
import { solutionQuery } from "../../../api/solutions";
import {
  PartitionBusinessHours,
  PartitionBusinessHoursZod,
  PartitionDetail,
  PartitionDetailZod,
} from "../models/partition";
import { PaginatedResponse, paginatedResponse } from "../models/response";

const PARTITIONS_API = "/Datastore/Partitions";

async function getPartition(
  partitionId: string | number
): Promise<PartitionDetail> {
  return PartitionDetailZod.parse(
    (await api.get(`${PARTITIONS_API}/${partitionId}`)).data
  );
}

export const partitionQuery = (partitionId: string | number | undefined) => ({
  queryKey: ["partition", ...(partitionId ? [partitionId.toString()] : [])],
  queryFn: () => getPartition(partitionId ?? ""),
  enabled: !!partitionId,
});

async function updatePartition(
  partitionId: string,
  patch: Partial<PartitionDetail>
) {
  return PartitionDetailZod.parse(
    (await api.patch(`${PARTITIONS_API}/${partitionId}`, patch)).data
  );
}

export const useEditPartition = (solutionId: string, partitionId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (patch: Partial<PartitionDetail>) =>
      updatePartition(partitionId, patch),
    onSuccess: (newPartition) => {
      queryClient.setQueryData(["partition", partitionId], newPartition);
      queryClient.invalidateQueries(solutionQuery(solutionId));
    },
    onError: () => {
      toast.error(
        t(
          "The changes were not saved. Please wait or refresh the page to start over."
        )
      );
    },
  });
};

async function getPartitionBusinessHours(
  solutionId: string,
  options: EndpointOptions = {}
): Promise<PaginatedResponse<PartitionBusinessHours[]>> {
  const query = getQueryString(options);
  const resultsQ = paginatedResponse(PartitionBusinessHoursZod.array()).parse(
    (
      await api.get(
        `/Datastore/Solutions/${solutionId}/PlanningAreasBusinessHours?${query}`
      )
    ).data
  );
  return resultsQ;
}

export const partitionBusinessHoursQuery = (
  solutionId: string,
  options?: EndpointOptions
) => ({
  queryKey: [
    "partitionBusinessHours",
    solutionId,
    ...(options ? [options] : []),
  ],
  queryFn: () => getPartitionBusinessHours(solutionId, options),
});
