import React, { type JSX } from "react";

import classNames from "classnames";

import { useTableContext } from "./Table";

interface TableRowProps {
  id?: string;
  noHover?: boolean;
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  onClick?(): void;
}

export default function TableRow(props: TableRowProps): JSX.Element {
  const { id, children, className = "", noHover = false, style } = props;
  const { onClick } = props;

  const { bordered } = useTableContext();

  return (
    <tr
      id={id}
      style={style}
      className={classNames(
        !noHover ? "hover:bg-slate-50" : "",
        bordered ? "border divide-x" : "",
        className
      )}
      onClick={onClick}
    >
      {children}
    </tr>
  );
}
