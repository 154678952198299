import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getShiftPlanPath } from "./shiftsAndStaffing";

const Constraints = lazyWithRetry(
  () => import("../pages/Constraints/Constraints")
);

const CONSTRAINTS_ROUTE = "constraints";

export const constraintsRoute: RouteObject = {
  path: CONSTRAINTS_ROUTE,
  element: <Constraints />,
};

export function getConstraintsPath(
  solutionId: string | number,
  shiftPlanId: string | number
): string {
  return `${getShiftPlanPath(solutionId, shiftPlanId)}/${CONSTRAINTS_ROUTE}`;
}
