import { DataImportConfig } from "../../../../PrognosAI/models/dataImportConfig";

export type MissingMapping = "timestamp" | "measurement" | "partitioner";

export default function useMappingsCheck(
  dataset: DataImportConfig | undefined
): MissingMapping[] {
  const missingMappings: MissingMapping[] = [];
  if (!dataset) {
    return [];
  }

  if (
    (dataset.dcicCsvColumns?.filter(
      (c) => c.columnType === "DateTime" || c.columnType === "Date"
    ).length ?? 0) !== 1
  ) {
    missingMappings.push("timestamp");
  }

  if (!dataset.dcicCsvColumns?.some((c) => c.columnType === "Numeric")) {
    missingMappings.push("measurement");
  }

  if (!dataset.dcicCsvColumns?.some((c) => c.columnType === "Categorical")) {
    missingMappings.push("partitioner");
  }

  return missingMappings;
}
