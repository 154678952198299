import React, { type JSX } from "react";

import { useAccount } from "../../../components/AuthProvider";
import { FilterSetting } from "../../../models/primitives";
import ProjectsTable from "../components/ProjectsTable";

export default function OwnProjects(): JSX.Element {
  const account = useAccount();

  const filters: FilterSetting[] = [];
  if (account?.user?.userName) {
    filters.push(["auditCreatedBy.username", "=", account?.user?.userName]);
  }

  return <ProjectsTable id="ownProjectsTable" filter={filters} />;
}
