import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";

import { useQuery } from "@tanstack/react-query";

import { EndpointOptions } from "../../../../../api";
import Card from "../../../../../components/Card";
import DataTable from "../../../../../components/DataTable";
import StatusIcon from "../../../../../components/StatusIcon";
import { DEFAULT_PAGE_SIZE } from "../../../../../services/constants";
import {
  resultSegmentSparklinesQuery,
  resultSegmentsQuery,
} from "../../../api/results";
import Sparkline from "../../../components/charts/Sparkline";
import useEndpoint from "../../../hooks/useEndpoint";
import useScrollToAnchor from "../../../hooks/useScrollToAnchor";
import {
  ResultDetail,
  ResultMetricKey,
  ResultMetricKeyZod,
  ResultSegmentSparkline,
  getMetricKey,
} from "../../../models/result";
import { ResultMetric } from "../../../models/settings";
import { getMetricInfo } from "./MetricsSummary";

export const SEGMENT_ENDP_KEY = "ResultSegments";
export const DEFAULT_SEGMENT_OPTIONS: EndpointOptions = {
  sort: [["partition.fullName", "asc"]],
  pageSize: DEFAULT_PAGE_SIZE,
};

interface SegmentsTableProps {
  runResultId: string | undefined;
  result: ResultDetail | undefined;
  hasMetrics: boolean;
  selectedMetric: ResultMetric;
  hasAggregates: boolean;
}

export default function SegmentsTable(props: SegmentsTableProps): JSX.Element {
  const { runResultId, result } = props;
  const { hasMetrics, selectedMetric, hasAggregates } = props;

  useScrollToAnchor();

  const { t } = useTranslation();

  const endpoint = useEndpoint(
    SEGMENT_ENDP_KEY,
    (opts) => ({
      ...resultSegmentsQuery(runResultId ?? "", opts),
      enabled: !!runResultId,
    }),
    DEFAULT_SEGMENT_OPTIONS
  );
  const { queryResult, options, appliedOptions } = endpoint;
  const { onSortChange, onFilterChange } = endpoint;
  const { items: resultSegments = [], count } = queryResult.data ?? {};

  const { data: { items: sparklines = [] } = {}, ...sparklinesRes } = useQuery(
    resultSegmentSparklinesQuery(runResultId, endpoint.appliedOptions)
  );
  const normalizedSparklines = getNormalizedSparklines(sparklines);

  const { name } = getMetricInfo(selectedMetric);

  const otherUsedMetrics = getOtherMetrics(appliedOptions, selectedMetric);

  const hasMoreModels = (result?.frozenModels.length ?? 1) > 1;

  return (
    <Card id="segmentsCard">
      <DataTable
        id="resultSegmentsTable"
        showSkeleton={queryResult.isLoading}
        rowKey="dataSegmentResultId"
        linkKey="link"
        rows={resultSegments.map((seg) => ({
          ...seg,
          statusIcon: (
            <StatusIcon status={seg.status} subStatus={seg.subStatus} />
          ),
          link: `${seg.dataSegmentResultId}${window.location.search}`,
          preview: getSparkline(
            `sparklineSegmentChart-${seg.dataSegmentResultId}`,
            normalizedSparklines.find(
              (sp) => sp.dataSegmentResultId === seg.dataSegmentResultId
            ),
            sparklinesRes.isLoading
          ),
          forecastMean:
            normalizedSparklines.find(
              (sp) => sp.dataSegmentResultId === seg.dataSegmentResultId
            )?.forecastMean ?? null,
        }))}
        columns={[
          {
            key: "statusIcon",
            apiKey: "status",
            type: "enum",
            label: t("Status"),
            sortable: true,
            content: "icon",
          },
          {
            key: "partitionFullName",
            apiKey: "partition.fullName",
            label: t("Planning area"),
            type: "string",
            sortable: true,
            filterable: true,
            highlighted: true,
          },
          {
            key: "partitionIsAggregate",
            apiKey: "partition.isAggregate",
            label: t("Aggregate"),
            type: "boolean",
            sortable: true,
            filterable: true,
            hidden: !hasAggregates,
          },
          {
            key: "measurementName",
            apiKey: "measurement.name",
            label: t("Observable"),
            type: "string",
            sortable: true,
            filterable: true,
            highlighted: true,
          },
          {
            key: "modelName",
            apiKey: "modelName",
            label: t("Model"),
            type: "string",
            sortable: true,
            filterable: true,
            shrink: "wrap",
            hidden: !hasMoreModels,
          },
          {
            key: "modelVersion",
            apiKey: "modelVersion",
            label: t("Version"),
            type: "string",
            sortable: true,
            filterable: true,
            shrink: "wrap",
            hidden: !hasMoreModels,
          },
          {
            key: "problem",
            apiKey: "problem",
            label: t("Problem"),
            type: "string",
            sortable: true,
            filterable: true,
            shrink: "wrap",
          },
          {
            key: "preview",
            type: "enum",
            label: t("Preview"),
            className: "!py-0",
            hidden:
              sparklinesRes.isError ||
              (!sparklinesRes.isLoading && normalizedSparklines.length === 0),
          },
          {
            key: "forecastMean",
            type: "number",
            label: t("Forecast mean"),
            hidden:
              sparklinesRes.isError ||
              (!sparklinesRes.isLoading && normalizedSparklines.length === 0),
          },
          {
            key: getMetricKey(selectedMetric),
            label: name,
            type: "number",
            sortable: true,
            filterable: true,
            highlighted: true,
            hidden: !hasMetrics,
          },
          ...otherUsedMetrics.map((otherMetric) => ({
            key: otherMetric,
            label: getMetricInfo(otherMetric).name,
            type: "number" as const,
            sortable: true,
            filterable: true,
          })),
        ]}
        page={options.page}
        pageSize={options.pageSize}
        totalCount={count}
        sort={options.sort}
        filter={options.filter}
        onSort={onSortChange}
        onFilter={onFilterChange}
      />
    </Card>
  );
}

function getOtherMetrics(
  appliedOptions: EndpointOptions,
  selectedMetric: ResultMetric
) {
  return [
    ...new Set(
      (appliedOptions.filter ?? [])
        .map((f) => f[0])
        .concat(appliedOptions.sort?.map((s) => s[0]) ?? [])
        .filter(
          (col: string | ResultMetricKey): col is ResultMetricKey =>
            ResultMetricKeyZod.safeParse(col).success
        )
        .filter((metric) => metric !== getMetricKey(selectedMetric))
    ),
  ];
}

function getSparkline(
  id: string,
  data: ResultSegmentSparkline | undefined,
  isLoading: boolean
): JSX.Element | null {
  return (
    <Sparkline
      id={id}
      data={data}
      isLoading={isLoading}
      className="h-12 w-32 sm:w-40 lg:w-52 xl:w-64 2xl:w-96"
    />
  );
}

export function getNormalizedSparklines(
  sparklines: ResultSegmentSparkline[]
): ResultSegmentSparkline[] {
  if (sparklines.length === 0) {
    return [];
  }

  const firstHistoryPts = sparklines.flatMap((sp) => {
    const firstPt = sp.data.at(0);
    if (!firstPt) {
      return [];
    }
    return [firstPt.x];
  });
  const firstForecastPts = sparklines.flatMap((sp) => {
    const firstPt = sp.data.find((e) => typeof e.yForecast === "number");
    if (!firstPt) {
      return [];
    }
    return [firstPt.x];
  });
  const lastForecastPts = sparklines.flatMap((sp) => {
    const lastPtIndex = sp.data.findLastIndex(
      (e) => typeof e.yForecast === "number"
    );
    if (lastPtIndex === -1) {
      return [];
    }
    return [sp.data[lastPtIndex].x];
  });
  if (firstForecastPts.length === 0 && lastForecastPts.length === 0) {
    return [];
  }

  const firstHistoryPt =
    firstHistoryPts.length > 0
      ? firstHistoryPts.reduce(
          (agg, curr) => (curr.valueOf() < agg.valueOf() ? curr : agg),
          firstHistoryPts[0]
        )
      : null;
  const firstForecastPt = firstForecastPts.reduce(
    (agg, curr) => (curr.valueOf() < agg.valueOf() ? curr : agg),
    firstForecastPts[0]
  );
  const lastForecastPt = lastForecastPts.reduce(
    (agg, curr) => (curr.valueOf() > agg.valueOf() ? curr : agg),
    lastForecastPts[0]
  );

  const delta = lastForecastPt.valueOf() - firstForecastPt.valueOf();
  const minPt = new Date(
    Math.max(
      firstHistoryPt?.valueOf() ?? 0,
      firstForecastPt.valueOf() - 2 * delta
    )
  );
  const maxPt = lastForecastPt;

  return sparklines
    .map((sp) => {
      const newData = sp.data.filter((e) => e.x >= minPt && e.x <= maxPt);
      const firstPt = newData.at(0);
      const lastPt = newData.at(-1);
      if (firstPt && firstPt.x.valueOf() > minPt.valueOf()) {
        newData.unshift({ x: minPt, yHistory: null });
      }
      if (lastPt && lastPt.x.valueOf() < maxPt.valueOf()) {
        newData.push({ x: maxPt, yForecast: null });
      }
      return { ...sp, data: newData };
    })
    .filter((sp) => sp.data.some((e) => typeof e.yForecast === "number"));
}
