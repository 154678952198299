import React, { type JSX } from "react";

import SkeletonCard from "../../../../../components/skeletons/SkeletonCard";
import SkeletonHero from "../../../../../components/skeletons/SkeletonHero";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import SegmentsTable from "./SegmentsTable";

interface ResultDashboardSkeletonProps {
  runResultId: string | undefined;
}

export default function ResultDashboardSkeleton(
  props: ResultDashboardSkeletonProps
): JSX.Element {
  const { runResultId } = props;

  return (
    <div className="space-y-4">
      <div>
        <SkeletonWrapper noLoading>
          <SkeletonHero />
        </SkeletonWrapper>
        <div className="grid sm:grid-cols-3 gap-4">
          <SkeletonDashboardCard />
          <SkeletonDashboardCard />
          <SkeletonDashboardCard />
        </div>
      </div>
      <SkeletonWrapper>
        <SegmentsTable
          runResultId={runResultId}
          result={undefined}
          hasMetrics={false}
          selectedMetric="RSquared"
          hasAggregates={false}
        />
      </SkeletonWrapper>
    </div>
  );
}

export function SkeletonDashboardCard(): JSX.Element {
  return (
    <SkeletonWrapper spinnerSize={24}>
      <SkeletonCard style={{ height: 240 }} />
    </SkeletonWrapper>
  );
}
