import React, { type JSX } from "react";
import { Navigate, useLocation } from "react-router";

import { useAuth } from "../../components/AuthProvider";

export default function RequireAuth({ children }: { children: JSX.Element }) {
  const session = useAuth();
  const { pathname, search, hash } = useLocation();

  if (!session.isAuthenticated && !session.isLoading) {
    return (
      <Navigate to="/" state={{ from: pathname + search + hash }} replace />
    );
  }

  return children;
}
