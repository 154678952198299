import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getShiftPlanPath } from "./shiftsAndStaffing";

const Result = lazyWithRetry(
  () => import("../../ShiftsAndStaffing/pages/Result/Result")
);

const RESULT_PATH = "result";

export const resultRoute: RouteObject = {
  path: RESULT_PATH,
  element: <Result />,
};

export function getResultPath(
  solutionId: string | number,
  shiftPlanId: string | number
): string {
  return `${getShiftPlanPath(solutionId, shiftPlanId)}/${RESULT_PATH}`;
}
