import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";

import { TFunction } from "i18next";

import {
  CircleStackIcon,
  ClockIcon,
  Cog6ToothIcon,
  HomeIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import { useSolution } from "./api/solutions";
import { getAnalyzerPath } from "./apps/Analyzer/routes/analyzer";
import { getDataStorePath } from "./apps/DataStore/routes/dataStore";
import { getDemandPath } from "./apps/Demand/routes/demand";
import useSettings from "./apps/PrognosAI/hooks/useSettings";
import { getPrognosPath } from "./apps/PrognosAI/routes/prognos";
import { getShiftsAndStaffingPath } from "./apps/ShiftsAndStaffing/routes/shiftsAndStaffing";
import { CustomIcon } from "./components/sidebar/Sidebar";
import AnalyzerIcon from "./icons/AnalyzerIcon";
import DemandIcon from "./icons/DemandIcon";
import PrognosIcon from "./icons/PrognosIcon";
import { HeroIcon } from "./models/primitives";
import { getSolutionSettingsPath } from "./routes/solutionSettings";
import { getSolutionPath } from "./routes/solutions";
import { getUserSettingsPath } from "./routes/userSettings";

export type SuiteApp =
  | "Home"
  | "DataStore"
  | "Analyzer"
  | "PrognosAI"
  | "Demand"
  | "ShiftsAndStaffing"
  | "ProjectSettings"
  | "UserSettings";

export type SuiteAppProps = {
  key: SuiteApp;
  link: string;
  label: string;
  altLabel?: string;
  icon?: HeroIcon | CustomIcon;
  useSquaresIcon?: boolean;
  hideInBreadcrumbs?: boolean;
  featureFlagged?: boolean;
};

export function getSuiteApps(
  solutionId: string | number,
  t: TFunction
): SuiteAppProps[] {
  return [
    {
      key: "Home",
      link: getSolutionPath(solutionId),
      label: t("Home"),
      altLabel: t("TIS Suite"),
      icon: HomeIcon,
      useSquaresIcon: true,
      hideInBreadcrumbs: true,
    },
    {
      key: "DataStore",
      link: getDataStorePath(solutionId),
      label: t("Data store"),
      icon: CircleStackIcon,
    },
    {
      key: "Analyzer",
      link: getAnalyzerPath(solutionId),
      label: "Analyzer",
      icon: AnalyzerIcon,
    },
    {
      key: "PrognosAI",
      link: getPrognosPath(solutionId),
      label: "Prognos AI",
      icon: PrognosIcon,
    },
    {
      key: "Demand",
      link: getDemandPath(solutionId),
      label: "Demand",
      icon: DemandIcon,
    },
    {
      key: "ShiftsAndStaffing",
      link: getShiftsAndStaffingPath(solutionId),
      label: t("Shifts and staffing"),
      icon: ClockIcon,
      featureFlagged: true,
    },
    {
      key: "ProjectSettings",
      link: getSolutionSettingsPath(solutionId),
      label: t("Project settings"),
      icon: Cog6ToothIcon,
      hideInBreadcrumbs: true,
    },
    {
      key: "UserSettings",
      link: getUserSettingsPath(solutionId, undefined),
      label: t("User settings"),
      icon: UserIcon,
      hideInBreadcrumbs: true,
    },
  ];
}

export function useSuiteApps(): SuiteAppProps[] {
  const [, solutionId] = useSolution();
  const { t } = useTranslation();

  const { featureFlags } = useSettings();

  return getSuiteApps(solutionId, t).filter(
    (app) => !app.featureFlagged || featureFlags[app.key] === "show"
  );
}

function getCurrentApp(pathname: string, apps: SuiteAppProps[]): SuiteAppProps {
  apps.sort((a, b) => b.link.localeCompare(a.link));

  for (const app of apps) {
    if (pathname.startsWith(app.link)) {
      return app;
    }
  }

  return apps.at(-1)!;
}

export function useCurrentApp(): SuiteAppProps {
  const { pathname } = useLocation();

  const apps = useSuiteApps();

  return getCurrentApp(pathname, apps);
}

export function useCurrentAppOrUndefined(): SuiteAppProps | undefined {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { solutionId } = useParams();
  if (!solutionId) {
    return undefined;
  }

  const apps = getSuiteApps(solutionId, t);

  return getCurrentApp(pathname, apps);
}

function getAppProps(
  solutionId: string | number,
  app: SuiteApp,
  t: TFunction | undefined
): SuiteAppProps {
  const apps = getSuiteApps(
    solutionId,
    t ?? (((key: string) => key) as TFunction)
  );
  const appProps = apps.find((a) => a.key === app);
  if (!appProps) {
    throw new Error(
      `App "${app}" is missing in the definitions. Please add it.`
    );
  }

  return appProps;
}

export function getAppPathPrefix(
  solutionId: string | number,
  app: SuiteApp
): string {
  return getAppProps(solutionId, app, undefined).link;
}
