import React, { JSX } from "react";
import Helmet from "react-helmet";

interface SuiteHelmetProps {
  title: string | string[];
}

export default function SuiteHelmet(props: SuiteHelmetProps): JSX.Element {
  const { title } = props;

  const titleString = useTitle(title);

  return (
    <Helmet>
      <title>{titleString}</title>
    </Helmet>
  );
}

const SEPARATOR = " - ";

function useTitle(parts: string[] | string): string {
  const result = Array.isArray(parts) ? [...parts] : [parts];

  result.push("TIS Suite");

  return result.join(SEPARATOR);
}
