import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";

import { XMarkIcon } from "@heroicons/react/20/solid";

import TextField from "./form/TextField";

interface SearchFieldProps {
  id: string;
  label?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onClear?(): void;
}

export default function SearchField(props: SearchFieldProps): JSX.Element {
  const { id, label, value, onChange, onClear } = props;

  const { t } = useTranslation();

  return (
    <TextField
      id={id}
      label={label}
      placeholder={t("Search") + "..."}
      value={value}
      onChange={onChange}
      action={
        value && onClear ? (
          <button
            id={`${id}-clearSearchButton`}
            className="flex items-center"
            onClick={onClear}
          >
            <XMarkIcon className="h-5 w-5 text-gray-500" />
          </button>
        ) : undefined
      }
    />
  );
}
