import React from "react";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

import useD3Locale from "../../apps/PrognosAI/hooks/useD3Locale";

const PLUS_MINUS_YEARS = 5;

interface CustomDatePickerHeaderProps extends ReactDatePickerCustomHeaderProps {
  minDate?: Date | null;
  maxDate?: Date | null;
}

export const CustomDatePickerHeader = (props: CustomDatePickerHeaderProps) => {
  const { date, prevMonthButtonDisabled, nextMonthButtonDisabled } = props;
  const { minDate = null, maxDate = null } = props;
  const { changeYear, changeMonth, decreaseMonth, increaseMonth } = props;

  const year = date.getFullYear();
  const years = useDropdownYears(date, minDate, maxDate);
  const months = useDropdownMonths(date, minDate, maxDate);

  return (
    <div className="w-full flex justify-between px-0.5 py-2">
      <button
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        type="button"
        className="disabled:cursor-not-allowed"
      >
        <ChevronLeftIcon className="w-5 h-5 text-gray-500" />
      </button>

      <select
        value={date.getMonth()}
        onChange={({ target: { value } }) => changeMonth(parseInt(value))}
        className="font-medium text-base"
      >
        {months.map((option) => (
          <option key={option.label} value={option.index} className="text-sm">
            {option.label}
          </option>
        ))}
      </select>

      <select
        value={year}
        onChange={({ target: { value } }) => changeYear(parseInt(value))}
        className="font-medium text-base"
      >
        {years.map((option) => (
          <option key={option} value={option} className="text-sm">
            {option}
          </option>
        ))}
      </select>

      <button
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        type="button"
        className="disabled:cursor-not-allowed"
      >
        <ChevronRightIcon className="w-5 h-5 text-gray-500" />
      </button>
    </div>
  );
};

function useDropdownYears(
  date: Date,
  minDate: Date | null,
  maxDate: Date | null
) {
  const years = [...Array(1 + PLUS_MINUS_YEARS * 2).keys()]
    .map((i) => date.getFullYear() + i - PLUS_MINUS_YEARS)
    .filter((year) => {
      if (minDate && minDate.getFullYear() > year) {
        return false;
      }

      if (maxDate && maxDate.getFullYear() < year) {
        return false;
      }

      return true;
    });

  return years;
}

function useDropdownMonths(
  date: Date,
  minDate: Date | null,
  maxDate: Date | null
) {
  const locale = useD3Locale();

  let months = locale.months.map((label, index) => ({ label, index }));

  if (minDate && minDate.getFullYear() === date.getFullYear()) {
    months = months.filter((m) => m.index >= minDate.getMonth());
  }

  if (maxDate && maxDate.getFullYear() === date.getFullYear()) {
    months = months.filter((m) => m.index <= maxDate.getMonth());
  }

  return months;
}
