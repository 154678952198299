import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { useQuery } from "@tanstack/react-query";

import { solutionQuery } from "../api/solutions";
import { useCurrentAppOrUndefined } from "../apps";
import { getSolutionPath } from "../routes/solutions";
import Breadcrumb from "./Breadcrumb";
import Breadcrumbs from "./Breadcrumbs";
import SuiteHelmet from "./helmet/SuiteHelmet";

interface PathLevel {
  label: React.ReactNode;
  address: string;
}

export type HeroPath = (PathLevel | null)[];

interface HeroBreadcrumbsProps {
  path: HeroPath;
  hideRootPath?: boolean;
  omitHelmet?: boolean;
}

export default function HeroBreadcrumbs(
  props: HeroBreadcrumbsProps
): JSX.Element {
  const { path, hideRootPath, omitHelmet } = props;

  const { solutionId } = useParams();
  const { data: solution } = useQuery(solutionQuery(solutionId));

  const { t } = useTranslation();
  const app = useCurrentAppOrUndefined();

  const fullPath: HeroPath = [...path];
  let titlePath: HeroPath = [...path];
  if (!hideRootPath) {
    if (solutionId) {
      if (app && !app.hideInBreadcrumbs) {
        fullPath.unshift({ address: app.link, label: app.label });
      }

      fullPath.unshift(
        solution
          ? { address: getSolutionPath(solutionId), label: solution.name }
          : null
      );
    }
    titlePath = [...fullPath];
    fullPath.unshift({ address: "/auth", label: t("Projects") });
  }

  titlePath.reverse();

  return (
    <>
      {!omitHelmet && (
        <SuiteHelmet
          title={titlePath.flatMap((e) =>
            typeof e?.label === "string" ? [e.label] : []
          )}
        />
      )}
      <Breadcrumbs>
        {fullPath.map((level, index) =>
          level ? (
            <Breadcrumb
              key={level.address}
              to={level.address}
              truncate={index === fullPath.length - 1}
            >
              {index < fullPath.length - 1
                ? truncateLabel(level.label)
                : level.label}
            </Breadcrumb>
          ) : (
            <div
              key={index}
              className="w-20 h-4 rounded-full animate-pulse bg-gray-200"
            ></div>
          )
        )}
      </Breadcrumbs>
    </>
  );
}

const LABEL_MAX_LEN = 40;
function truncateLabel(label: React.ReactNode): React.ReactNode {
  if (typeof label !== "string") {
    return label;
  }

  if (label.length > LABEL_MAX_LEN) {
    return <span title={label}>{label.slice(0, LABEL_MAX_LEN).trim()}...</span>;
  }

  return label;
}
