import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";

import { Cog6ToothIcon } from "@heroicons/react/24/outline";

import { useSolution } from "../../../api/solutions";
import AutosaveIndicator from "../../../components/AutosaveIndicator";
import Card from "../../../components/Card";
import HeroActionButton from "../../../components/HeroActionButton";
import Slideover from "../../../components/Slideover";
import SkeletonCard from "../../../components/skeletons/SkeletonCard";
import SkeletonWrapper from "../../../components/skeletons/SkeletonWrapper";
import useAutosave, { EntityErrors } from "../../PrognosAI/hooks/useAutosave";
import { Analysis, useAnalysis, useEditAnalysis } from "../api/analysis";
import DataSettings from "./DataSettings";

type SettingsBarRenderProps = {
  analysis: Analysis;
  onChange(patch: Partial<Analysis>): void;
  errors: EntityErrors<Analysis>;
};

interface SettingsBarProps {
  pageTitle: string | null;
  children?:
    | React.ReactNode
    | ((props: SettingsBarRenderProps) => React.ReactNode);
  quickSettings?: React.ReactNode;
}

export default function SettingsBar(props: SettingsBarProps): JSX.Element {
  const { pageTitle, children, quickSettings } = props;
  const { t } = useTranslation();

  const [, solutionId] = useSolution();
  const [{ data: origAnalysis }, analysisId] = useAnalysis();

  const editAnalysis = useEditAnalysis(solutionId, analysisId);
  const autosave = useAutosave(origAnalysis, editAnalysis.mutateAsync, {
    delay: 1000,
  });
  const { entity: analysis, handleChange, status, errors } = autosave;

  const [openSlideover, setOpenSlideover] = React.useState(false);

  return (
    <div
      className="w-96 space-y-4 overflow-y-auto rounded-md"
      style={{ maxHeight: "calc(100vh - 60px)" }}
    >
      <Card>
        <div className="flex items-center justify-between space-x-2">
          <div className="grow">
            {!!analysis && (
              <h1 className="text-xl font-bold">{analysis?.name}</h1>
            )}
            {!analysis && (
              <div className="w-full h-7">
                <div className="rounded animate-pulse bg-gray-200 w-3/4 h-full" />
              </div>
            )}
            {!!pageTitle && (
              <h2 className="text-base font-medium text-gray-400">
                {pageTitle}
              </h2>
            )}
            {!pageTitle && (
              <div className="w-full h-6 py-1">
                <div className="rounded animate-pulse bg-gray-100 w-1/2 h-full" />
              </div>
            )}
          </div>
          {!!children && !!analysis && (
            <div>
              <HeroActionButton
                id="showAllPageSettings"
                title={t("Open chart settings")}
                onClick={() => setOpenSlideover(true)}
              >
                <Cog6ToothIcon />
              </HeroActionButton>
              <Slideover
                title={pageTitle + " - " + t("Settings")}
                open={openSlideover}
                onClose={() => setOpenSlideover(false)}
              >
                <div className="space-y-4">
                  {typeof children === "function"
                    ? children({ analysis, onChange: handleChange, errors })
                    : children}
                </div>
              </Slideover>
            </div>
          )}
        </div>
        {!!quickSettings && (
          <div className="space-y-4 py-2 empty:hidden">{quickSettings}</div>
        )}
      </Card>
      {!!analysis && (
        <Card className="space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="text-base font-semibold">{t("Data selection")}</h3>
            <p className="w-5 h-5">
              <AutosaveIndicator status={status} noStyle />
            </p>
          </div>
          <DataSettings analysis={analysis} onChange={handleChange} contained />
        </Card>
      )}
      {!analysis && (
        <SkeletonWrapper spinnerSize={24}>
          <SkeletonCard style={{ height: 300 }} />
        </SkeletonWrapper>
      )}
    </div>
  );
}
