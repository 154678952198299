import React from "react";

import { Aggregate } from "../models/aggregate";
import { Invalid } from "../models/primitives";
import ButtonGroup from "./buttons/ButtonGroup";
import FieldSkeleton from "./skeletons/FieldSkeleton";

interface LargeTimescaleButtonsProps {
  id: string;
  label?: string;
  options: Aggregate[];
  value: Aggregate | Invalid | null;
  disabled?: boolean;
  isLoading?: boolean;
  onChange: (scale: Aggregate) => void;
}

export default function LargeTimescaleButtons(
  props: LargeTimescaleButtonsProps
) {
  const { id, label, options, value, disabled, isLoading, onChange } = props;

  if (isLoading) {
    return (
      <FieldSkeleton
        fieldId={id}
        fieldClassName="py-2 pl-3 pr-10"
        label={label}
      />
    );
  }

  const realValue = value !== "Invalid" ? value : null;

  return (
    <div className="w-full">
      {!!label && (
        <label
          htmlFor={id}
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          {label}
        </label>
      )}
      <ButtonGroup
        id={id}
        wrapperClassName={"w-full"}
        options={options.map((key) => ({ key }))}
        active={realValue ?? undefined}
        disabled={disabled}
        onClick={onChange}
      />
    </div>
  );
}
