import React from "react";
import { RouteObject } from "react-router";

import { z } from "zod";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getDataStorePath } from "./dataStore";

const Dataset = lazyWithRetry(
  () => import("../pages/DataImportConfig/DataImportConfig")
);
const DatasetSource = lazyWithRetry(
  () => import("../pages/DataImportConfig/sections/DatasetSource")
);
const DatasetMappings = lazyWithRetry(
  () => import("../pages/DataImportConfig/sections/DatasetMappings")
);
const DatasetTimestampFormat = lazyWithRetry(
  () => import("../pages/DataImportConfig/sections/DatasetTimestampFormat")
);
const DatasetObservableSettings = lazyWithRetry(
  () => import("../pages/DataImportConfig/sections/DatasetObservableSettings")
);
const DatasetCategorySettings = lazyWithRetry(
  () => import("../pages/DataImportConfig/sections/DatasetCategorySettings")
);
const DatasetImportSettings = lazyWithRetry(
  () => import("../pages/DataImportConfig/sections/DatasetImportSettings")
);
const Import = lazyWithRetry(
  () => import("../../DataStore/pages/Import/Import")
);

const IMPORT_PATH = "import";

export const datasetPages = [
  "source",
  "mapping",
  "timestamps",
  "observables",
  "categories",
  "import",
] as const;
export const DatasetPageZod = z.enum(datasetPages);
export type DatasetPage = z.infer<typeof DatasetPageZod>;

const PageEnum = DatasetPageZod.Enum;

export const importRoute: RouteObject = {
  path: IMPORT_PATH,
  children: [
    { index: true, element: <Import /> },
    {
      path: ":dataImportConfigId",
      element: <Dataset />,
      children: [
        { path: PageEnum.source, element: <DatasetSource /> },
        { path: PageEnum.mapping, element: <DatasetMappings /> },
        { path: PageEnum.timestamps, element: <DatasetTimestampFormat /> },
        { path: PageEnum.observables, element: <DatasetObservableSettings /> },
        { path: PageEnum.categories, element: <DatasetCategorySettings /> },
        { path: PageEnum.import, element: <DatasetImportSettings /> },
      ],
    },
  ],
};

export function getImportPath(solutionId: string | number): string {
  return `${getDataStorePath(solutionId)}/${IMPORT_PATH}`;
}

export function getImportDetailPath(
  solutionId: string | number,
  dataCollectionImportConfigId: string | number
): string {
  return `${getImportPath(solutionId)}/${dataCollectionImportConfigId}`;
}

export function getImportDetailSectionPath(
  solutionId: string | number,
  dataCollectionImportConfigId: string | number,
  section: DatasetPage
): string {
  return `${getImportDetailPath(solutionId, dataCollectionImportConfigId)}/${section}`;
}
