import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import {
  ArrowDownTrayIcon,
  CommandLineIcon,
  GlobeEuropeAfricaIcon,
  LockClosedIcon,
  PresentationChartLineIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import SideMenu from "../../../apps/PrognosAI/components/sidemenu/SideMenu";
import SideMenuEntry from "../../../apps/PrognosAI/components/sidemenu/SideMenuEntry";
import { SettingsPage, SettingsPageZod } from "../../../routes/userSettings";

export default function SettingsMenu(): JSX.Element {
  const pages = usePages();
  const page = useCurrentPage();

  return (
    <SideMenu>
      {pages.map((item) => (
        <SideMenuEntry
          key={item.id}
          id={item.id}
          name={item.name}
          href={`./${item.id}`}
          icon={item.icon}
          active={item.id === page}
          disabled={item.disabled}
        />
      ))}
    </SideMenu>
  );
}

interface NavItem {
  id: SettingsPage;
  name: string;
  icon: typeof TrashIcon;
  disabled?: boolean;
}

const usePages = (): NavItem[] => {
  const { t } = useTranslation();

  return [
    {
      id: "localization",
      name: t("Localization"),
      icon: GlobeEuropeAfricaIcon,
    },
    { id: "export", name: t("Export"), icon: ArrowDownTrayIcon },
    {
      id: "visualizations",
      name: t("Visualizations"),
      icon: PresentationChartLineIcon,
    },
    { id: "apiKeys", name: t("API keys"), icon: LockClosedIcon },
    { id: "developerTools", name: t("Developer tools"), icon: CommandLineIcon },
  ];
};

const useCurrentPage = (): SettingsPage => {
  const location = useLocation();
  const paths = location.pathname.split("/");

  return SettingsPageZod.catch("localization").parse(paths[paths.length - 1]);
};
