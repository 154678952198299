import React, { type JSX } from "react";
import { Link } from "react-router";

import classNames from "classnames";

interface BreadcrumbProps {
  to: string;
  children: React.ReactNode;
  truncate?: boolean;
}

export default function Breadcrumb(props: BreadcrumbProps): JSX.Element {
  const { children, to, truncate } = props;
  return (
    <Link
      to={to}
      className={classNames(
        "no-underline hover:underline hover:underline-offset-2 text-slate-400 text-nowrap",
        truncate ? "truncate" : ""
      )}
    >
      {children}
    </Link>
  );
}
