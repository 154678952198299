import React, { type JSX } from "react";

import classNames from "classnames";

import PROGNOS_VERSION, { FIX_VERSION } from "../../services/version";

interface VersionProps {
  short?: boolean;
}

export default function Version(props: VersionProps): JSX.Element {
  const { short } = props;

  return (
    <div
      className={classNames(
        "w-full text-xs md:text-[10px] text-gray-400 px-2 py-1",
        short ? "text-center" : "text-left"
      )}
      title={short ? PROGNOS_VERSION : undefined}
    >
      {short ? FIX_VERSION : PROGNOS_VERSION}
    </div>
  );
}
