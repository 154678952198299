import React from "react";
import { useTranslation } from "react-i18next";

import { useQueryClient } from "@tanstack/react-query";

import { solutionQuery } from "../../../api/solutions";
import { defaultBusinessHoursQuery } from "../api/businessHours";
import {
  businessHoursImportsQuery,
  factorsImportsQuery,
  importExportConfigQuery,
} from "../api/importExport";
import { getFactorsQueryPrefix } from "../api/influencingFactors";
import { partitionQuery } from "../api/partitions";
import {
  activeImportTasksQuery,
  finishedEntityImportTasksQuery,
} from "../api/tasks";
import { Message } from "../models/message";
import {
  GeneralSolutionTaskZod,
  TaskStatus,
  finalTaskStates,
} from "../models/task";

export default function useImportMessageCallback() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const callback = React.useCallback(
    (msg: Message) => {
      const parseInfo = GeneralSolutionTaskZod.safeParse(msg.eventArgs);
      if (!parseInfo.success) {
        return;
      }
      const task = parseInfo.data;
      const solutionId = task.solutionId.toString();

      if (!task.taskName.startsWith("Import")) {
        return;
      }

      const continuousKeysToInvalidate = [
        activeImportTasksQuery(solutionId, "ImportInfluencingFactors"),
        activeImportTasksQuery(solutionId, "ImportBusinessHours"),
      ];

      for (const query of continuousKeysToInvalidate) {
        queryClient.invalidateQueries(query);
      }

      const finalKeysToInvalidate: { queryKey: unknown[] }[] = [
        solutionQuery(solutionId),
        factorsImportsQuery(solutionId),
        businessHoursImportsQuery(solutionId),
        getFactorsQueryPrefix(solutionId),
        defaultBusinessHoursQuery(solutionId),
        partitionQuery(undefined),
      ];

      if (task.importExportConfigId) {
        finalKeysToInvalidate.push(
          importExportConfigQuery(task.importExportConfigId),
          finishedEntityImportTasksQuery(
            solutionId,
            `${task.importExportConfigId}`
          )
        );
      }

      if ((finalTaskStates as readonly TaskStatus[]).includes(task.status)) {
        for (const query of finalKeysToInvalidate) {
          queryClient.invalidateQueries(query);
        }
      }
    },
    [queryClient, t]
  );

  return callback;
}
