import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";

import ErrorCard from "../../components/ErrorCard";
import { getSolutionsPath } from "../../routes/solutions";

import secureFiles from "../../apps/PrognosAI/images/illustrations/secureFiles.svg";

export default function Forbidden(): JSX.Element {
  const { t } = useTranslation();

  return (
    <section className="flex justify-center">
      <ErrorCard
        title="Error 403"
        subtitle={t("forbidden")}
        image={secureFiles}
      >
        <p>{t("You do not have access to the page you are trying to open.")}</p>
        <p>
          {t("Please return")}{" "}
          <Link to={getSolutionsPath()}>{t("back to the main page")}</Link>.{" "}
        </p>
      </ErrorCard>
    </section>
  );
}
