import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import api, { EndpointOptions, getQueryString } from "../../../api";
import { PaginatedResponse, paginatedResponse } from "../models/response";
import {
  RunFixedModel,
  RunFixedModelZod,
  RunPartition,
  RunPartitionZod,
} from "../models/runFixedModels";
import { RUNS_API, runQuery } from "./runs";
import { activeRunTasksQuery, activeSolutionRunTasksQuery } from "./tasks";

const FIXED_MODELS_API = "/Prognos/RunConfigFixedModels";
const RUN_PARTITIONS_API = "/Prognos/RunConfigPartitions";

async function getRunFixedModels(
  runConfigId: string | number
): Promise<RunFixedModel[]> {
  return RunFixedModelZod.array().parse(
    (await api.get(`${RUNS_API}/${runConfigId}/FixedModels`)).data
  );
}

export const runFixedModelsQuery = (runConfigId: string | number) => ({
  queryKey: ["runFixedModels", `${runConfigId}`],
  queryFn: () => getRunFixedModels(runConfigId),
});

type RunFixedModelDraft = Pick<RunFixedModel, "modelId">;

async function createRunFixedModel(
  runConfigId: string | number,
  runFixedModel: RunFixedModelDraft
): Promise<RunFixedModel> {
  return RunFixedModelZod.parse(
    (await api.post(`${RUNS_API}/${runConfigId}/FixedModels`, runFixedModel))
      .data
  );
}

export const useCreateRunFixedModel = (runConfigId: string | number) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (runFixedModel: RunFixedModelDraft) =>
      createRunFixedModel(runConfigId, runFixedModel),
    onSuccess: () => {
      queryClient.invalidateQueries(runFixedModelsQuery(runConfigId));
    },
    onError: () => {
      toast.error(t("An error occurred while saving. Please try again."));
    },
  });
};

async function deleteRunFixedModel(fixedModelId: string | number) {
  return api.delete(`${FIXED_MODELS_API}/${fixedModelId}`);
}

export const useDeleteRunFixedModel = (runConfigId: string | number) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: deleteRunFixedModel,
    onSuccess: () => {
      queryClient.invalidateQueries(runFixedModelsQuery(runConfigId));
      queryClient.invalidateQueries(runQuery(runConfigId));
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};

async function getRunPartitions(
  runConfigId: string | number,
  options: EndpointOptions = {}
): Promise<PaginatedResponse<RunPartition[]>> {
  const query = getQueryString(options);
  return paginatedResponse(RunPartitionZod.array()).parse(
    (await api.get(`${RUNS_API}/${runConfigId}/Partitions?${query}`)).data
  );
}

export const runPartitionsQuery = (
  runConfigId: string | number,
  options?: EndpointOptions
) => ({
  queryKey: ["runPartitions", `${runConfigId}`, ...(options ? [options] : [])],
  queryFn: () => getRunPartitions(runConfigId, options),
});

async function updateRunPartition(
  runConfigPartitionId: string | number,
  patch: Partial<RunPartition>
) {
  return RunPartitionZod.parse(
    (await api.patch(`${RUN_PARTITIONS_API}/${runConfigPartitionId}`, patch))
      .data
  );
}

export const useUpdateRunPartition = (runConfigId: string | number) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({
      runConfigPartitionId,
      patch,
    }: {
      runConfigPartitionId: string | number;
      patch: Partial<RunPartition>;
    }) => updateRunPartition(runConfigPartitionId, patch),
    onSuccess: () => {
      queryClient.invalidateQueries(runPartitionsQuery(runConfigId));
      queryClient.invalidateQueries(runFixedModelsQuery(runConfigId));
    },
    onError: () => {
      toast.error(t("An error occurred while updating. Please try again."));
    },
  });
};

async function startFixedModels(runConfigId: string | number) {
  return api.post(`${RUNS_API}/${runConfigId}/StartRun`);
}

export const useStartFixedModels = (solutionId: string | number) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: startFixedModels,
    onSuccess: (_, runConfigId) => {
      queryClient.invalidateQueries(activeSolutionRunTasksQuery(solutionId));
      queryClient.invalidateQueries(activeRunTasksQuery(runConfigId));
    },
    onError: () => {
      toast.error(
        t("An error occurred while starting the scenario. Please try again.")
      );
    },
  });
};

async function startFixedModelsAutoAssignment(runConfigId: string | number) {
  return api.post(`${RUNS_API}/${runConfigId}/ModelAutoSelection`);
}

export const useStartFixedModelsAutoAssignment = (
  solutionId: string | number
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: startFixedModelsAutoAssignment,
    onSuccess: (_, runConfigId) => {
      queryClient.invalidateQueries(activeSolutionRunTasksQuery(solutionId));
      queryClient.invalidateQueries(activeRunTasksQuery(runConfigId));
    },
    onError: () => {
      toast.error(
        t(
          "An error occurred while starting the automated model assignment. Please try again."
        )
      );
    },
  });
};
