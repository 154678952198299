import React, { type JSX } from "react";

import SkeletonCard from "./SkeletonCard";
import SkeletonHero from "./SkeletonHero";
import SkeletonWrapper from "./SkeletonWrapper";

interface ListPageSkeletonProps {
  showTabs?: boolean;
}

export default function ListPageSkeleton(
  props: ListPageSkeletonProps
): JSX.Element {
  const { showTabs } = props;

  return (
    <>
      <SkeletonWrapper noLoading>
        <SkeletonHero />
      </SkeletonWrapper>
      {showTabs && (
        <div className="w-full px-3 mb-4">
          <div className="w-full h-9 bg-gray-200 rounded-md"></div>
        </div>
      )}
      <SkeletonWrapper className="grow">
        <SkeletonCard className="h-full" style={{ height: undefined }} />
      </SkeletonWrapper>
    </>
  );
}
