import React from "react";
import { RouteObject } from "react-router";

import { SuiteApp, getAppPathPrefix } from "../apps";
import lazyWithRetry from "../services/lazyWithRetry";
import { getSolutionPath, getSolutionsPath } from "./solutions";

const TaskDetail = lazyWithRetry(
  () => import("../pages/TaskDetail/TaskDetail")
);
const TasksOverview = lazyWithRetry(
  () => import("../pages/TasksOverview/TasksOverview")
);

const TASKS_PATH = "tasks";

export const tasksRoute: RouteObject = {
  path: TASKS_PATH,
  children: [
    { index: true, element: <TasksOverview /> },
    { path: ":taskId", element: <TaskDetail /> },
  ],
};

export function getTasksPath(
  solutionId: string | number | undefined,
  app: SuiteApp | undefined
): string {
  if (!solutionId) {
    return `${getSolutionsPath()}/${TASKS_PATH}`;
  }

  if (!app) {
    return `${getSolutionPath(solutionId)}/${TASKS_PATH}`;
  }

  return `${getAppPathPrefix(solutionId, app)}/${TASKS_PATH}`;
}

export function getTaskDetailPath(
  solutionId: string | number | undefined,
  taskId: string,
  app: SuiteApp | undefined
): string {
  return `${getTasksPath(solutionId, app)}/${taskId}`;
}
