import React from "react";
import { RouteObject } from "react-router";

import { userSettingsRoute } from "../../../routes/userSettings";
import lazyWithRetry from "../../../services/lazyWithRetry";
import ShiftsAndStaffingLayout from "../layouts/ShiftsAndStaffingLayout";
import { shiftPlanRoute } from "./shiftPlan";
import { SHIFTS_AND_STAFFING_PATH } from "./shiftsAndStaffing";

const Dashboard = lazyWithRetry(
  () => import("../../ShiftsAndStaffing/pages/Dashboard/Dashboard")
);

export const shiftsAndStaffingRoute: RouteObject = {
  path: SHIFTS_AND_STAFFING_PATH,
  element: <ShiftsAndStaffingLayout />,
  children: [
    { index: true, element: <Dashboard /> },
    shiftPlanRoute,
    userSettingsRoute,
  ],
};
