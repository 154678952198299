import React, { type JSX } from "react";

import classNames from "classnames";

import CircularLoading from "../CircularLoading";

interface SkeletonWrapperProps {
  children: React.ReactNode;
  noLoading?: boolean;
  spinnerSize?: number;
  className?: string;
}

export default function SkeletonWrapper(
  props: SkeletonWrapperProps
): JSX.Element {
  const { children, noLoading = false, spinnerSize = 64, className } = props;

  return (
    <div className={classNames("relative", className)}>
      <div className="animate-pulse h-full">{children}</div>
      {!noLoading && (
        <div className="absolute top-[50%] left-[50%] text-blue-400 -translate-x-1/2 -translate-y-1/2">
          <CircularLoading size={spinnerSize} />
        </div>
      )}
    </div>
  );
}
