import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getShiftPlanPath } from "./shiftsAndStaffing";

const FixedShifts = lazyWithRetry(
  () => import("../pages/FixedShifts/FixedShifts")
);

const FixedShift = lazyWithRetry(
  () => import("../pages/FixedShift/FixedShift")
);

const FIXED_SHIFTS_PATH = "fixedShifts";

export const fixedShiftsRoute: RouteObject = {
  path: FIXED_SHIFTS_PATH,
  children: [
    { index: true, element: <FixedShifts /> },
    { path: ":fixedShiftId", element: <FixedShift /> },
  ],
};

export function getFixedShiftsPath(
  solutionId: string | number,
  shiftPlanId: string | number
): string {
  return `${getShiftPlanPath(solutionId, shiftPlanId)}/${FIXED_SHIFTS_PATH}`;
}

export function getFixedShiftPath(
  solutionId: string | number,
  shiftPlanId: string | number,
  fixedShiftId: string | number
): string {
  return `${getFixedShiftsPath(solutionId, shiftPlanId)}/${fixedShiftId}`;
}
