import React, { type JSX } from "react";

import classNames from "classnames";

interface FieldSkeletonProps {
  fieldId?: string;
  fieldClassName?: string;
  label?: string;
}

export default function FieldSkeleton(props: FieldSkeletonProps): JSX.Element {
  const { fieldId, fieldClassName, label } = props;

  return (
    <div className="w-full">
      {!!label && (
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <div
        id={fieldId}
        className={classNames(
          "w-full h-[38px] animate-pulse bg-gray-200 rounded",
          fieldClassName
        )}
      ></div>
    </div>
  );
}
