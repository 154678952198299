import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";

import Alert from "../../../../../components/Alert";
import SearchField from "../../../../../components/SearchField";
import ListRadio from "../../../../../components/form/ListRadio";
import useLocalStorage from "../../../hooks/useLocalStorage";
import {
  Partition,
  ViewMode,
  ViewModeZod,
  viewModes,
} from "../../../models/partition";
import { Partitioner } from "../../../models/partitioner";
import { tenum } from "../../../services/translationMappings";
import SelectablePartitionNode, {
  PartitionAction,
  PartitionNode,
} from "./SelectablePartitionNode";

interface SelectablePartitionTreeProps {
  id: string;
  partitions: Partition[];
  partitioners: Partitioner[];
  usedPartitions: Partition["partitionId"][];
  actions?: PartitionAction[];
  defaultOpen?: boolean;
  selectSubtrees?: boolean;
  disabled?: boolean;
  forcedView?: ViewMode;
  onChange(partitionIds: number[]): void;
}

export default function SelectablePartitionTree(
  props: SelectablePartitionTreeProps
): JSX.Element {
  const { id, partitioners, partitions, usedPartitions } = props;
  const { actions, defaultOpen, selectSubtrees = false } = props;
  const { disabled = false, forcedView } = props;
  const { onChange } = props;

  const [searchValue, setSearchValue] = React.useState("");
  const [selectedView, setView] = useLocalStorage(
    "partitionsViewMode",
    ViewModeZod.parse("all"),
    ViewModeZod
  );
  const view = forcedView ?? selectedView;

  const { t } = useTranslation();

  const rootNode = buildHierarchy(partitions);

  if (rootNode === null) {
    return (
      <Alert type="info">
        {t("No planning areas are available. Please import some data first.")}
      </Alert>
    );
  }

  const hasHierarchy = partitioners.length > 1;
  const showModeSelector = hasHierarchy && !selectSubtrees;

  return (
    <>
      {showModeSelector && !forcedView && (
        <ModeSelector
          id={`${id}-viewRadio`}
          view={view}
          onViewChange={setView}
          partitioners={partitioners}
          partitions={partitions}
          usedPartitions={usedPartitions}
        />
      )}
      <SearchField
        id={`${id}-searchField`}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onClear={() => setSearchValue("")}
      />
      <ol className="mt-2">
        <SelectablePartitionNode
          treeId={id}
          ancestors={[]}
          partitionNode={rootNode}
          onChange={onChange}
          defaultOpen={defaultOpen}
          selectedPartitionIds={usedPartitions}
          actions={actions}
          search={searchValue}
          view={showModeSelector || !!forcedView ? view : "all"}
          selectSubtrees={selectSubtrees}
          disabled={disabled}
        />
      </ol>
    </>
  );
}

function buildHierarchy(allPartitions: Partition[]): PartitionNode | null {
  const root = allPartitions.find((p) => p.parentPartitionId === null);
  if (!root) {
    return null;
  }

  return {
    partition: root,
    children: getChildNodes(
      root,
      allPartitions.filter((p) => p.parentPartitionId === root.partitionId),
      allPartitions
    ),
  };
}

function getChildNodes(
  parent: Partition,
  children: Partition[],
  allPartitions: Partition[]
): PartitionNode[] {
  return children.map((child) => ({
    parent,
    partition: child,
    children: getChildNodes(
      child,
      allPartitions.filter((p) => p.parentPartitionId === child.partitionId),
      allPartitions
    ),
  }));
}

type ModeSelectorProps = {
  id: string;
  partitioners: Partitioner[];
  partitions: Partition[];
  usedPartitions: number[];
  view: ViewMode;
  onViewChange(view: ViewMode): void;
};

function ModeSelector(props: ModeSelectorProps): JSX.Element {
  const { id, view, onViewChange } = props;
  const { partitioners, partitions, usedPartitions } = props;
  const { t } = useTranslation();

  const sortedPartitioners = [...partitioners];
  sortedPartitioners.sort((a, b) => a.order - b.order);

  const usedObjects = partitions.filter((p) =>
    usedPartitions.includes(p.partitionId)
  );
  const usedLeaves = usedObjects.filter(
    (p) => p.partitionerId === partitioners.at(-1)?.partitionerId
  );
  const allLeaves = partitions.filter(
    (p) => p.partitionerId === partitioners.at(-1)?.partitionerId
  );

  const totalUsedCount = usedPartitions.length;
  const usedLeavesCount = usedLeaves.length;
  const usedAggregatesCount = totalUsedCount - usedLeavesCount;

  const totalCount = partitions.length;
  const totalLeavesCount = allLeaves.length;
  const totalAggregatesCount = totalCount - totalLeavesCount;

  return (
    <ListRadio
      id={id}
      label={t("Show")}
      size="small"
      ySpace="space-y-2 mx-1"
      labelYMargin="mt-1"
      options={viewModes.map((key) => ({
        key,
        value: key,
        label: (
          <span className="font-normal">
            {tenum(`ViewMode:${key}`, t)}{" "}
            <span className="text-xs text-gray-400">
              {key === "all" && !!totalUsedCount && (
                <>
                  {totalUsedCount}/{totalCount}
                </>
              )}
              {key === "aggregates" && !!usedAggregatesCount && (
                <>
                  {usedAggregatesCount}/{totalAggregatesCount}
                </>
              )}
              {key === "leaves" && !!usedLeavesCount && (
                <>
                  {usedLeavesCount}/{totalLeavesCount}
                </>
              )}
            </span>
          </span>
        ),
      }))}
      value={{ key: view }}
      onChange={({ key }) => onViewChange(key as ViewMode)}
    />
  );
}
