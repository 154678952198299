import React from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { AiOutlineCaretLeft, AiOutlineCaretRight } from "react-icons/ai";

import useSettings from "../apps/PrognosAI/hooks/useSettings";
import { DateFormat } from "../apps/PrognosAI/models/settings";
import ErrorBoundary from "./ErrorBoundary";
import { CustomDatePickerHeader } from "./form/CustomDatePickerHeader";

type FormattedDatePickerProps = DatePicker["props"];

export default function FormattedDatePicker(props: FormattedDatePickerProps) {
  const settings = useSettings();
  const dateFormat = transformDateFormat(settings.dateFormat);

  const { minDate, maxDate } = props;

  const { t } = useTranslation();

  return (
    <ErrorBoundary>
      <DatePicker
        dateFormat={dateFormat}
        previousMonthButtonLabel={<AiOutlineCaretLeft />}
        nextMonthButtonLabel={<AiOutlineCaretRight />}
        calendarStartDay={1}
        showPopperArrow={false}
        renderCustomHeader={(innerProps) => (
          <CustomDatePickerHeader
            {...innerProps}
            minDate={props.minDate}
            maxDate={props.maxDate}
          />
        )}
        portalId="portal-root"
        fixedHeight
        {...props}
        placeholderText={
          props.openToDate
            ? formatDate(props.openToDate, dateFormat)
            : props.placeholderText
        }
        renderDayContents={(day, date) => {
          if ((minDate && date < minDate) || (maxDate && date > maxDate)) {
            return <span title={t("No data available.")}>{day}</span>;
          }

          return <>{day}</>;
        }}
      />
    </ErrorBoundary>
  );
}

/**
 * Transform d3 date format (%d %m %Y) to dd MM yyyy format.
 * @param format d3 date format string
 * @returns dd MM yyyy format string
 */
function transformDateFormat(format: DateFormat) {
  return format.replace("%Y", "yyyy").replace("%m", "MM").replace("%d", "dd");
}

function formatDate(date: Date, format: string) {
  return format
    .replace("yyyy", date.getFullYear().toString())
    .replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
    .replace("dd", date.getDate().toString().padStart(2, "0"));
}
