import React, { type JSX } from "react";

import classNames from "classnames";

import { useTableContext } from "./Table";

interface TableHeadProps {
  children?: React.ReactNode;
  className?: string;
}

export default function TableHead(props: TableHeadProps): JSX.Element {
  const { children, className } = props;

  const { bordered } = useTableContext();

  return (
    <thead className={classNames(bordered ? "bg-gray-50" : "", className)}>
      {children}
    </thead>
  );
}
