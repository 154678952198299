import React, { type JSX } from "react";

import classNames from "classnames";

import { useTableContext } from "./Table";

interface TableBodyProps {
  children?: React.ReactNode;
  noDivide?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export default function TableBody(props: TableBodyProps): JSX.Element {
  const { children, noDivide = false, className, style } = props;

  const { bordered } = useTableContext();

  return (
    <tbody
      className={classNames(
        !noDivide && !bordered ? "divide-y divide-gray-200" : "",
        className
      )}
      style={style}
    >
      {children}
    </tbody>
  );
}
