import React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useQueryClient } from "@tanstack/react-query";

import {
  activeDataAnalysisTasksQuery,
  activeDataImportTasksQuery,
} from "../../../../PrognosAI/api/tasks";
import { DataImportConfig } from "../../../../PrognosAI/models/dataImportConfig";
import { Message } from "../../../../PrognosAI/models/message";
import {
  DatasetTaskZod,
  TaskStatus,
  finalTaskStates,
} from "../../../../PrognosAI/models/task";
import { dataImportConfigQuery } from "../../../api/dataImportConfigs";

export const useDatasetMessagesCallback = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const callback = React.useCallback(
    (msg: Message) => {
      const parser = DatasetTaskZod.safeParse(msg.eventArgs);
      if (!parser.success) {
        return;
      }

      const task = parser.data;

      const solutionId = task.solutionId.toString();
      const dataCollectionImportConfigId =
        task.dataCollectionImportConfigId.toString();
      queryClient.setQueryData<DataImportConfig>(
        dataImportConfigQuery(dataCollectionImportConfigId).queryKey,
        (prev) => (prev ? { ...prev, responsibleTask: task } : prev)
      );

      const tasksToInvalidate = [
        activeDataAnalysisTasksQuery(solutionId),
        activeDataImportTasksQuery(solutionId),
      ];

      for (const query of tasksToInvalidate) {
        queryClient.invalidateQueries(query);
      }

      if ((finalTaskStates as readonly TaskStatus[]).includes(task.status)) {
        // import changes so many things that it's easier to invalidate everything
        queryClient.invalidateQueries();
      }
      if (task.status === "Failed") {
        return toast.error(
          task.taskName === "DataCollectionImportAnalysis"
            ? t("The dataset failed to be analyzed. Please try again.")
            : t("The dataset failed to be imported. Please try again.")
        );
      }
      if (task.status === "Canceled") {
        return toast.success(
          task.taskName === "DataCollectionImportAnalysis"
            ? t("The dataset analysis was canceled successfully.")
            : t("The dataset import was canceled successfully.")
        );
      }
      if (task.status === "Finished") {
        return toast.success(
          task.taskName === "DataCollectionImportAnalysis"
            ? t("The dataset analysis was finished successfully.")
            : t("The dataset import was finished successfully.")
        );
      }
    },
    [queryClient, t]
  );

  return callback;
};
