import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import {
  ArchiveBoxIcon,
  RocketLaunchIcon,
  SparklesIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";

import { SolutionPhase } from "../../../../../models/solution";

interface PhaseIconProps {
  phase: SolutionPhase;
  className?: string;
}

export default function PhaseIcon(props: PhaseIconProps): JSX.Element {
  const { phase, className = "" } = props;
  const { t } = useTranslation();

  const fullClassName = classNames("w-6 h-6", className);

  switch (phase) {
    case "New":
      return (
        <SparklesIcon
          title={t("First steps stage")}
          className={classNames(fullClassName, "text-gray-400")}
        />
      );
    case "Development":
      return (
        <WrenchScrewdriverIcon
          title={t("Development stage")}
          className={classNames(fullClassName, "text-gray-400")}
        />
      );
    case "Production":
      return (
        <RocketLaunchIcon
          title={t("Production stage")}
          className={classNames(fullClassName, "text-gray-400")}
        />
      );
    case "Archived":
      return (
        <ArchiveBoxIcon
          title={t("Archived")}
          className={classNames(fullClassName, "text-gray-400")}
        />
      );
  }
}
