import React, { type JSX } from "react";

import classNames from "classnames";

import HelpTooltip from "../../../../components/HelpTooltip";

interface NarrowFormItemProps {
  id?: string;
  label: React.ReactNode;
  htmlFor: string;
  children: React.ReactNode;
  standalone?: boolean;
  help?: string;
  isDefault?: boolean;
  className?: string;
  forceNarrow?: boolean;
}

export default function NarrowFormItem(
  props: NarrowFormItemProps
): JSX.Element {
  const { id, label, htmlFor, children, standalone, help } = props;
  const { isDefault = false, className = "", forceNarrow = false } = props;
  return (
    <div
      id={id}
      className={classNames(
        !forceNarrow
          ? "pt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4"
          : "",
        !standalone ? "px-6" : "",
        !standalone && !forceNarrow ? "sm:border-t sm:border-gray-200" : "",
        className
      )}
    >
      <label
        htmlFor={htmlFor}
        className={classNames(
          "block text-sm",
          !forceNarrow ? "sm:pt-2" : "",
          isDefault ? "font-light text-gray-600" : "font-medium text-gray-700"
        )}
      >
        {help ? (
          <span className="flex items-center space-x-1">
            <span>{label}</span>
            <HelpTooltip
              id={`${htmlFor}-tooltip`}
              text={help}
              variant="light"
              place="right"
              className="!bg-gray-50"
            />
          </span>
        ) : (
          label
        )}
      </label>
      <div
        className={classNames(
          "mt-1",
          !forceNarrow ? "sm:col-span-2 sm:mt-0 sm:h-full" : ""
        )}
      >
        {children}
      </div>
    </div>
  );
}
